/* eslint-disable no-confusing-arrow */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// eslint-disable-next-line import/no-unresolved
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// eslint-disable-next-line import/no-unresolved
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// eslint-disable-next-line import/no-unresolved
import dayjs from 'dayjs';
import Checkbox from '../checkbox/Checkbox';
import './timeSelector.css';

const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
];

const TimeSelector = ({ triggerTime, setTriggerTime }) => {
  const defaultStartTime = dayjs().hour(0).minute(0).second(0);
  const defaultEndTime = dayjs().hour(23).minute(59).second(0);

  const [days, setDays] = useState(
    daysOfWeek.map((day) => {
      const existingTrigger = triggerTime?.find((t) => t.day === day);
      return {
        name: day,
        enabled: !!existingTrigger,
        startTime: existingTrigger ? dayjs(`2023-01-01T${existingTrigger.startTime}:00`) : defaultStartTime,
        endTime: existingTrigger ? dayjs(`2023-01-01T${existingTrigger.endTime}:00`) : defaultEndTime,
      };
    }),
  );

  const handleCheckboxChange = (day) => {
    setDays((prev) => prev.map((d) => d.name === day ? { ...d, enabled: !d.enabled } : d));
  };

  const handleTimeChange = (day, timeType, value) => {
    setDays((prev) => prev.map((d) => d.name === day ? { ...d, [timeType]: value } : d));
  };

  useEffect(() => {
    const triggeredTime = days
      .filter((day) => day.enabled)
      .map((day) => ({
        day: day.name,
        startTime: day.startTime.format('HH:mm'),
        endTime: day.endTime.format('HH:mm'),
      }));
    setTriggerTime(triggeredTime);
  }, [days, setTriggerTime]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        {days.map((day) => (
          <Grid container alignItems="center" spacing={2} key={day.name} style={{ marginBottom: '20px', marginLeft: '-5px' }}>
            <Grid item xs={1.6}>
              <FormControlLabel
                control={(
                  <Checkbox
                    width="16px"
                    height="16px"
                    borderRadius="4px"
                    checked={day.enabled}
                    onChange={() => handleCheckboxChange(day.name)}
                  />
                )}
                label={(
                  <Typography
                    sx={{
                      color: 'rgb(52, 64, 84)',
                      fontSize: '0.925rem',
                      fontWeight: 500,
                      marginLeft: '7px',
                    }}
                  >
                    {day.name}
                  </Typography>
                )}
              />
            </Grid>
            <Grid item xs="auto">
              {/* Start Time Picker */}
              <TimePicker
                label="Start Time"
                value={day.startTime}
                onChange={(newValue) => handleTimeChange(day.name, 'startTime', newValue)}
                disabled={!day.enabled}
                timeSteps={{ hours: 1, minutes: 1 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: '125px important!',
                      '& .MuiInputBase-root': {
                        padding: '2px 8px',
                        height: '36px',
                        borderRadius: '8px',
                      },
                      '& .MuiInputBase-input': {
                        color: 'rgb(52, 64, 84)',
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        padding: '4px',
                        height: '1.25rem',
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs="auto">
              <Typography
                sx={{
                  color: 'rgb(52, 64, 84)',
                  fontSize: '0.925rem',
                  fontWeight: 500,
                  height: '1.25rem',
                }}
              >
                to
              </Typography>
            </Grid>
            <Grid item xs="auto">
              {/* End Time Picker */}
              <TimePicker
                label="End Time"
                value={day.endTime}
                onChange={(newValue) => handleTimeChange(day.name, 'endTime', newValue)}
                disabled={!day.enabled}
                minTime={day.startTime}
                timeSteps={{ hours: 1, minutes: 1 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      width: '125px',
                      '& .MuiInputBase-root': {
                        padding: '2px 8px',
                        height: '36px',
                        borderRadius: '8px',
                      },
                      '& .MuiInputBase-input': {
                        color: 'rgb(52, 64, 84)',
                        fontSize: '0.875rem',
                        fontWeight: 500,
                        padding: '4px',
                        height: '1.25rem',
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        ))}
      </Box>
    </LocalizationProvider>
  );
};

export default TimeSelector;
