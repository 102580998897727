import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import MenuPopup from '../menuPopup/MenuPopup';
import VerticalDots from './img/VerticalDots.svg';
import FilterIcon from './img/FilterIcon.svg';
import './notificationConfigTab.css';

const Tab = styled.button`
display: flex;
flex-direction: row;
gap: 8px;
padding: 0.0625rem 0.25rem 0.6875rem 0.25rem;
cursor: pointer;
opacity: 0.6;
background: ${(props) => props.color};
border: 0;
outline: 0;
border-bottom: 0.125rem solid transparent;
transition: ease border-bottom 250ms;
${({ active }) => active
&& `
    border-bottom: 0.125rem solid #26404E;
    opacity: 1;
    `
}
`;

const NotifConfigTab = (props) => {
    const {
        color,
        types,
        unread,
        notifFilter,
        setNotifFilter,
        agencyRow,
        module,
        setData,
        data,
        markAsRead,
        fetchAlerts,
        selectedCheckboxes,
        checkboxStates,
        handleToggle,
    } = props;
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [menuType, setMenuType] = useState(null);

    const handleClick = (event, type) => {
        setAnchorEl(event.currentTarget);
        setMenuType(type);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setMenuType(null);
    };

    const stateChange = (e, nameValue) => {
        e.preventDefault();
        setNotifFilter(nameValue);
        if (nameValue === 'unread') {
            if (data) {
                const filteredData = data.filter((x) => x.read === false);
                setData(filteredData);
            }
        }
        if (nameValue === 'all') {
            fetchAlerts();
        }
    };

    const switchModule = (value, type) => {
        switch (value) {
            case 'notifications':
                return (
                    <>
                        {type.text === 'Unread' ? (
                            <>
                                <div className="notif-config-tab-text">
                                    {type.text}
                                </div>
                                {unread ? (
                                    <div className="notif-config-tab-users">
                                        {unread}
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </>
                        ) : (
                            <div className="notif-config-tab-text">
                                {type.text}
                            </div>
                        )}
                    </>
                );
            default:
                return (
                    <div className="notif-config-tab-text">
                        {type.text}
                    </div>
                );
        }
    };

    return (
        <>
            <div className="notif-config-tab-button-group">
                <div className="notif-config-tab-buttons">
                    {types.map(
                        (type) => type.enabled === true && (
                            <Tab
                              color={color}
                              key={type.text}
                              active={notifFilter === type.value}
                              onClick={(e) => stateChange(e, type.value)}
                            >
                                {switchModule(module, type)}
                            </Tab>
                        ),
                    )}
                </div>
                <div className="notif-config-container">
                    <div
                      className="notif-config-tab-button"
                      aria-hidden="true"
                      onClick={(event) => handleClick(event, 'notifications-filter')}
                    >
                        <div className="assets-menu">
                            <img loading="lazy" src={FilterIcon} alt="FilterIcon" />
                        </div>
                    </div>
                    <div
                      className="notif-config-tab-button"
                      aria-hidden="true"
                      onClick={(event) => handleClick(event, 'menu-options')}
                    >
                        <div className="assets-menu">
                            <img loading="lazy" src={VerticalDots} alt="MenuIcon" />
                        </div>
                    </div>
                </div>
                <Popover
                  sx={{
                    boxShadow: 2,
                    borderRadius: 2,
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                    {menuType === 'notifications-filter' && (
                        <MenuPopup
                          page="notifications-filter"
                          checkboxStates={checkboxStates}
                          handleToggle={handleToggle}
                        />
                    )}
                    {menuType === 'menu-options' && (
                        <MenuPopup
                          page="notifications"
                          markAsRead={markAsRead}
                          selectedCheckboxes={selectedCheckboxes}
                        />
                    )}
                </Popover>
            </div>
        </>
    );
};

export default NotifConfigTab;
