/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from 'react';
import Map, {
 Marker, Popup, Source, Layer, FlyToInterpolator,
} from 'react-map-gl';
import Supercluster from 'supercluster';
import WebMercatorViewport from '@math.gl/web-mercator';
import IdleIcon from './img/idle.svg';
// import TemperedIcon from './img/tempered.svg';
import InTowIcon from './img/intow.svg';
import MovingIcon from './img/moving.svg';
import StopIcon from './img/stop.svg';
import IdleDirection from './img/yellow_direction.svg';
// import TemperedDirection from './img/Tempered-direction.svg';
import InTowDirection from './img/Towing-direction.svg';
import MovingDirection from './img/Green_direction.svg';
import StopDirection from './img/Stop-direction.svg';
import { clusterLayer, clusterCountLayer, unclusteredPointLayer } from './layer';
import { onDataChangeWS } from '../../config/function/websocketFlyTo';
import './mapComponent.css';
import 'mapbox-gl/dist/mapbox-gl.css';

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

const MapComponent = (props) => {
  const {
    data, markerClick, markerPopUp, pointMarkerValue, assetCardDisp, clusterPoint, viewMapping, setViewMapping, oneTimeLoad, setOneTimeLoad, height = 500, width = 700, mapRefs, clusterClick,
  } = props;

  // this function is for first render fitbounds
  const getBoundsForPoints = (points) => {
    // console.log(points);
      const filterPoints = points.filter((n) => n && n.length !== 0);
      if (filterPoints.length > 0) {
          const pointsLong = filterPoints.map((point) => point.longitude);

          const pointsLat = filterPoints.map((point) => point.latitude);

          const cornersLongLat = [
              [(Math.min.apply(null, pointsLong)), (Math.min.apply(null, pointsLat))],
              [(Math.max.apply(null, pointsLong)), (Math.max.apply(null, pointsLat))],
          ];

          const viewport = new WebMercatorViewport({ width: width, height: height });

          const checkCorners = (cornerLongLat) => {
              if (Array.isArray(points) && points.length > 0) {
                  return isFinite(cornerLongLat[0][0]) && isFinite(cornerLongLat[0][1]) && isFinite(cornerLongLat[1][0]) && isFinite(cornerLongLat[1][1]);
              }
          };
          // console.log('cornerLongLat', cornersLongLat);
          // console.log(checkCorners(cornersLongLat));
          if (checkCorners(cornersLongLat)) {
              const { longitude, latitude, zoom } = viewport.fitBounds(cornersLongLat, { padding: 40 });
              // if (points.length < 3) {
              //   return { longitude, latitude, zoom: 12 };
              // }
              return { longitude, latitude, zoom };
          }
      } else {
          return { longitude: 101.6869, latitude: 3.1390, zoom: 10 };
      }
  };

  // this on onLoad it will render the bounds and add into state
  useEffect(() => {
    // console.log('dataaaa', data);
    if (oneTimeLoad) {
      const boundary = getBoundsForPoints(data);
      setViewMapping({ ...boundary });
      setOneTimeLoad(false);
    }
  }, [oneTimeLoad]);

  // console.log('pointMarker', Object.keys(pointMarkerValue).length > 0 && [pointMarkerValue.latitude, pointMarkerValue.longitude]);

  // this when there is markerClick will render the bounds and add into state
  useEffect(() => {
    if (Object.keys(pointMarkerValue).length > 0) {
      // console.log(pointMarkerValue);
      onDataChangeWS(pointMarkerValue, mapRefs);
    }
  }, [pointMarkerValue]);

// this to display markerImage;
  const markerImage = (motionInference, payload) => {
    switch (motionInference) {
      case 100:
          return (
            <div className="marker-container" onClick={(e) => markerClick(e, payload)} onKeyDown={(e) => markerClick(e, payload)}>
            {
              payload.heading ? <div className="radar-box"><div className="pointers-static" style={{ transform: `rotate(${payload.heading}deg)` }}><img loading="lazy" src={StopDirection} style={{ height: '10px', width: '10px' }} alt="arrow" /></div></div> : null
            }
              <img loading="lazy" src={StopIcon} alt="marker-icon" style={{ cursor: 'pointer' }} />
            </div>
          );
      case 200:
          return (
            <div className="marker-container" onClick={(e) => markerClick(e, payload)} onKeyDown={(e) => markerClick(e, payload)}>
              {
                payload.heading ? <div className="radar-box"><div className="pointers-static" style={{ transform: `rotate(${payload.heading}deg)` }}><img loading="lazy" src={MovingDirection} style={{ height: '10px', width: '10px' }} alt="arrow" /></div></div> : null
              }
              <img loading="lazy" src={MovingIcon} alt="marker-icon" style={{ cursor: 'pointer' }} />
            </div>
          );
      case 400:
          return (
            <div className="marker-container" onClick={(e) => markerClick(e, payload)} onKeyDown={(e) => markerClick(e, payload)}>
              {
                payload.heading ? <div className="radar-box"><div className="pointers-static" style={{ transform: `rotate(${payload.heading}deg)` }}><img loading="lazy" src={IdleDirection} style={{ height: '10px', width: '10px' }} alt="arrow" /></div></div> : null
              }
                <img loading="lazy" src={IdleIcon} alt="marker-icon" style={{ cursor: 'pointer' }} />
            </div>
          );
      case 300:
        return (
          <div className="marker-container" onClick={(e) => markerClick(e, payload)} onKeyDown={(e) => markerClick(e, payload)}>
            {
              payload.heading ? <div className="radar-box"><div className="pointers-static" style={{ transform: `rotate(${payload.heading}deg)` }}><img loading="lazy" src={InTowDirection} style={{ height: '10px', width: '10px' }} alt="arrow" /></div></div> : null
            }
              <img loading="lazy" src={InTowIcon} alt="marker-icon" style={{ cursor: 'pointer' }} />
          </div>
        );
      // case 500:
      //     return (
      //       <div className="marker-container" onClick={(e) => markerClick(e, payload)} onKeyDown={(e) => markerClick(e, payload)}>
      //         {
      //           payload.heading ? <div className="radar-box"><div className="pointers-static" style={{ transform: `rotate(${payload.heading}deg)` }}><img loading="lazy" src={TemperedDirection} style={{ height: '10px', width: '10px' }} alt="arrow" /></div></div> : null
      //         }
      //           <img loading="lazy" src={TemperedIcon} alt="marker-icon" style={{ cursor: 'pointer' }} />
      //       </div>
      //     );
      default:
          return null;
    }
  };

  // console.log('pointmarkervalue', pointMarkerValue);

  const reducer = (value) => {
    const featureCollection = {
      type: 'FeatureCollection',
      features: value.map((e) => {
        const conatiner = {
          type: 'Feature',
          properties: e,
          geometry: {
            type: 'Point',
            coordinates: [e.longitude, e.latitude],
          },
        };
        return conatiner;
      }),
    };
    return featureCollection;
  };

  const index = new Supercluster({ radius: 75, maxZoom: 20 }).load(reducer(data).features);

  const bounds = mapRefs?.current && mapRefs?.current.getMap() ? mapRefs?.current.getMap().getBounds().toArray().flat() : [1, 1, 1, 1];

  const clusters = index.getClusters(bounds, viewMapping.zoom);

  // console.log(clusters);

  // console.log(clusters.some((e) => e.properties.deviceOid === '64b604e8fca5ef0c8b05cc2b'));

  const moveCamera = (longitude, latitude, cluster) => {
    if (clusterClick && clusterClick === true) {
      const expansionZoom = index.getClusterExpansionZoom(
        cluster.properties.cluster_id,
      );
      mapRefs.current.flyTo({
        center: [longitude, latitude], zoom: expansionZoom, duration: 700,
      });
    }
  };

  return (
      <Map
        {...viewMapping}
        ref={mapRefs}
        onMove={(event) => setViewMapping(event.viewState)}
        mapStyle="mapbox://styles/asiamobiliti/ckb1yq6080opd1inb3lkdmbii"
        mapboxAccessToken={mapboxToken}
        width="100%"
        height="100%"
      >
        {
          clusters && clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const {
              cluster: isCluster,
              point_count: pointCount,
            } = cluster.properties;

            if (isCluster) {
              return (
                  <Marker
                    key={cluster.properties.cluster_id}
                    latitude={latitude}
                    longitude={longitude}
                  >
                    <div
                      key={cluster.properties.cluster_id}
                      className="cluster-marker"
                      style={{
                          width: `${10 + (pointCount / cluster.properties.point_count) * 20}px`,
                          height: `${10 + (pointCount / cluster.properties.point_count) * 20}px`,
                      }}
                      onClick={() => moveCamera(longitude, latitude, cluster)}
                    >
                      {pointCount}
                    </div>
                  </Marker>
              );
            }

            return (
              <>
                <Marker
                  key={cluster.properties.deviceOid}
                  longitude={longitude}
                  latitude={latitude}
                >
                  {markerImage(cluster.properties.motionInference, cluster.properties)}
                </Marker>
                {
                  clusters.some((e) => e.properties.deviceOid === pointMarkerValue.deviceOid) && (
                    <Popup
                      style={{
                        opacity: '60%',
                      }}
                      longitude={pointMarkerValue.longitude ?? null}
                      latitude={pointMarkerValue.latitude ?? null}
                      closeButton={false}
                      closeOnClick={false}
                    >
                      {pointMarkerValue.assetName ? pointMarkerValue.assetName : pointMarkerValue.deviceSerial}
                    </Popup>
                  )
                }
              </>
            );
          })
        }
        <style>
          {
            `
              .mapboxgl-popup-content {
                box-shadow: 0rem 0.75rem 1rem -0.875rem rgba(16, 24, 40, 0.08), 0rem 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03);
                border-radius: 0.5rem;
                font-weight: 600;
                font-size: 0.75rem;
                width: 100%;
                text-align: center;
                padding: 0.75rem 1rem;
              }
            `
          }
        </style>
      </Map>
  );
};

export default MapComponent;
