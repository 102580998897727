/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable-next-line no-trailing-spaces */
import React, { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material';
import useComponentVisible from '../../config/outsideClick/useComponentVisible';
import CheckedIcon from './img/check.svg';
import AssetIcon from './img/asset.svg';
import './autocomplete.css';

const loadTheme = createTheme({
  palette: {
    amcolor: {
      main: '#365A6D',
    },
  },
});

const Autocomplete = (props) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  const {
    list, setSelectionList, selectionList, text, isImage, isAgencySelector, stateInputValue, inputValue, alignment, width, selectedOption, setSelectedOption, disable, component, setDropDownLoading, loadAsset, autocompleteComponent,
  } = props;
  const refs = useRef(null);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const isDefault = useMediaQuery({
    query: '(min-width: 1024px)',
});
const isMobileOrTablet = useMediaQuery({
    query: '(max-width: 1023px)',
});
  // const [selectedOption, setSelectedOption] = useState(null);
  // console.log('selectedOption', selectedOption);
  // console.log('list', list);
  // this function for toggle display the list
  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
    setIsComponentVisible(true);
  };

  // this function for close the dropdown
  const setSelectedThenCloseDropdown = (index) => {
    setSelectedOption(index);
    setIsOptionsOpen(false);
    setIsComponentVisible(false);
    // setTest(true);
  };

  useEffect(() => {
    if (isComponentVisible === false) {
      stateInputValue('');
    }
  }, [isComponentVisible]);

  // this function for list keyDown
  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case ' ':
      case 'SpaceBar':
      case 'Enter':
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  // this function for list keydown
  const handleListKeyDown = (e) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        setIsOptionsOpen(false);
        setIsComponentVisible(false);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : list.length - 1,
        );
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedOption(
          selectedOption === list.length - 1 ? 0 : selectedOption + 1,
        );
        break;
      case 'Enter':
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  // this function for onInput div editable value onChange
  const inputOnchange = (e) => {
    e.preventDefault();
    stateInputValue(e.currentTarget.textContent);
  };

  const checkedSign = (c, index) => {
    switch (c) {
      case 'Geofence':
        return list.findIndex((e) => e.oid === selectionList.oid) === index;
      case 'AssetSearch':
        return list.findIndex((e) => e.uuid === selectionList.uuid) === index;
      case 'AlertType':
        return list.findIndex((e) => e.alertDisplayType === selectionList.alertDisplayType) === index;
      case 'AlertPolicy':
        return list.findIndex((e) => e.alertPolicy === selectionList.alertPolicy) === index;
      default:
        return Object.keys(selectionList).length > 0 && selectedOption === index;
    }
  };

  // Filter the list based on user input
  const filteredList = list.filter((option) => {
    const trimmedId = option?.id?.toString().trim();
    const trimmedName = option?.name?.trim();
    const normalizedInput = inputValue?.trim().toLowerCase();
    const idMatches = trimmedId && trimmedId.toLowerCase().includes(normalizedInput);
    const nameMatches = trimmedName && trimmedName.toLowerCase().includes(normalizedInput);
    return idMatches || nameMatches;
  });

  return (
    <div ref={ref} className="selection-wrapper" style={isMobileOrTablet ? { justifyContent: alignment, width: '100%' } : { justifyContent: alignment }}>
      <div className="selections-container" style={isMobileOrTablet ? { width: '100%' } : { width: width }}>
        <div
          style={disable ? {
            pointerEvents: 'none',
            background: 'rgb(249, 250, 251)',
            color: 'rgb(102, 112, 133)',
            opacity: '0.6',
            width: width,
          } : {
            width: width,
          }}
          className={isComponentVisible ? 'selection-button expanded' : 'selection-button'}
          onClick={toggleOptions}
          onKeyDown={handleListKeyDown}
          role="button"
          tabIndex="0"
        >
          {
              !_.isEmpty(selectionList) ? (
              <>
                {isImage && <img loading="lazy" src={AssetIcon} alt="asset-icon" />}
                <div
                  style={{
                    display: 'inline-grid',
                    gridAutoFlow: 'column',
                    maxWidth: '85%',
                    gridAutColumns: '1fr',
                    gap: '8px',
                    width: isComponentVisible ? '-webkit-fill-available' : '',
                  }}
                  className={isComponentVisible ? 'btn-list-placeholder' : 'btn-list-container'}
                  contentEditable={component !== 'ExportAlert' && !isAgencySelector && !disable}
                  suppressContentEditableWarning
                  onInput={(e) => inputOnchange(e)}
                >
                 {!isComponentVisible ? (
                    <>
                      {component === 'ExportAlert' ? (
                        <>
                          {selectionList.alertDisplayType && <div className="btn-list-agency-name">{selectionList.alertDisplayType}</div>}
                        </>
                      ) : (
                        <>
                          {component === 'adminConsole' ? (
                            <div className="btn-list-agency-name-noWrap">{selectionList.name}</div>
                          ) : (
                            <div className="btn-list-agency-name">{selectionList.name}</div>
                          )}
                          {component !== 'notificationDetails' && (
                            <div className={isAgencySelector ? 'btn-list-agency-id' : 'btn-list-asset-id'}>
                              {isAgencySelector ? `@${selectionList.id}` : selectionList.id}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : ('')}
                </div>
              </>
            ) : (
              <div className="btn-list-container" style={{ width: '-webkit-fill-available' }}>
                {isImage && <img loading="lazy" src={AssetIcon} alt="asset-icon" />}
                <div
                  className="btn-list-placeholder"
                  contentEditable={component !== 'ExportAlert' && !isAgencySelector}
                  suppressContentEditableWarning
                  onInput={(e) => inputOnchange(e)}
                >
                  {!isComponentVisible ? text : ''}
                </div>
              </div>
            )
          }
        </div>
        <ul
          className={`options ${isComponentVisible ? 'show' : ''}`}
          style={{
            width: `calc(${width} + 1rem)`,
            minHeight: '40px',
            maxHeight: isMobileOrTablet ? '10.5rem' : '18rem',
          }}
          role="listbox"
          aria-activedescendant={list[selectedOption]}
          tabIndex={-1}
          onKeyDown={handleListKeyDown}
        >
          {
            loadAsset ? (
              <>
                {loadAsset && (
                  <li
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <ThemeProvider theme={loadTheme}>
                      <CircularProgress
                        color="amcolor"
                        thickness="5"
                        style={{
                          width: '30px', height: '30px', justifyContent: 'center', display: 'flex',
                        }}
                      />
                    </ThemeProvider>
                  </li>
                )}
              </>
            ) : (
              filteredList.length > 0 ? (
                filteredList.map((option, index) => (
                  <li
                    id={option}
                    role="option"
                    style={{
                      display: 'flex', justifyContent: 'space-between', alignItem: 'center',
                    }}
                    aria-selected={checkedSign(autocompleteComponent, index)}
                    tabIndex={null}
                    onKeyDown={handleKeyDown(index)}
                    onClick={(e) => {
                      setSelectionList(option);
                      setSelectedThenCloseDropdown(index);
                    }}
                  >
                    {
                      isAgencySelector ? (
                        <div style={{ display: 'flex', alignItem: 'center', gap: '8px' }}>
                          {component === 'adminConsole' ? (
                            <div className="list-agency-noWrap">{option.name}</div>
                          ) : (
                            <div className="list-agency">{option.name}</div>
                          )}
                          <div className="list-agency-id">{option.id ? `@${option.id}` : '@admin'}</div>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: 'inline-grid',
                            gridAutoFlow: 'column',
                            maxWidth: '100%',
                            gridAutoColumns: '1fr',
                            gap: '8px',
                          }}
                        >
                          {component === 'ExportAlert' ? (
                            <>
                              {option.alertDisplayType && <div className="list-agency">{option.alertDisplayType}</div>}
                            </>
                          ) : (
                            <>
                              {option.name && <div className="list-agency">{option.name}</div>}
                              {component === 'AssetPerformance' ? (
                                <>
                                  {option.name ? (
                                    option.id && <div className="list-asset-id" style={{ maxWidth: '100%' }}>{option.id}</div>
                                  ) : (
                                    option.id && <div className="list-agency" style={{ maxWidth: '100%' }}>{option.id}</div>
                                  )}
                                </>
                              ) : (
                                component === 'notificationDetails' ? (
                                  <>
                                  </>
                                ) : (
                                  <>
                                    {option.id && <div className="list-asset-id">{option.id}</div>}
                                  </>
                                )
                              )}
                            </>
                          )}
                        </div>
                      )
                    }
                    {checkedSign(autocompleteComponent, index) && (<img loading="lazy" src={CheckedIcon} alt="check-icon" />)}

                  </li>
                ))
              ) : (
                <li
                  id="1"
                  style={{
                    display: 'flex', justifyContent: 'space-between', alignItem: 'center',
                  }}
                  tabIndex={null}
                >
                  <div className="btn-list-agency-name">No result found</div>
                </li>
              )
            )
          }
        </ul>
      </div>
    </div>
  );
};

export default Autocomplete;
