/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { useOutletContext } from 'react-router';
import { DateObject } from 'react-multi-date-picker';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import Title from '../../../component/title/Title';
// import { assetPerformanceNavigationList } from '../../../config/function/tabNavigationList';
import ConfigTab from '../../../component/configurationTab/configurationTab';
import Autocomplete from '../../../component/autocomplete/Autocomplete';
import {
    CreateWithIcon,
    LiveMapButton,
} from '../../../component/buttonComponent/ButtonComponent';
import Modal from '../../../component/modal/Modal';
import SnackBar from '../../../component/snackbar/Snackbar';
import DateRangePicker from '../../../component/dateRangePicker/DateRangePicker';
import { APIGetSearchAssetnAssetGroup, APIGetV3TelemetryLog, APIGetDevices } from '../../../config/restAPI/FetchAPI';
import { WebsocketContext } from '../../../config/reducer/Websocket';
import { useApiData } from '../../../config/reducer/Modules';
import './AssetPerformance.css';

// const navigationList = ['Telemetry Log', 'Trips', 'Alerts'];
const navigationList = [
    {
        text: 'Telemetry Log',
        value: 1,
        path: '/insights/assetPerformance',
        enabled: true,
    },
    // { text: 'Trips', value: 2, path: '/insights/trips' },
    // { text: 'Alerts', value: 3, path: '/insights/alerts' },
];

const AssetPerformance = (props) => {
    const { state } = useLocation();
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const userTimezone = Cookies.get('timezone');
    const { apiResponse } = useApiData();
    const [loadAsset, setLoadAsset] = useState(false); // for getSearchAsset loader
    const navigate = useNavigate();
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const [listAsset, setListAsset] = useState([]); // this is for list search asset dropdown
    const [assetSelectionList, setAssetSelectionList] = useState({}); // this for asset selection state
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [incorrectCredentials, setIncorrectCredentials] = useState(false); // this is for loading state
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState([]);
    const [inputValue, setInputValue] = useState('Select Dates');
    const [searchTimeout, setSearchTimeout] = useState(null);// buffer when do search
    const [selectedOption, setSelectedOption] = useState(null);
    const [uuid, setUuid] = useState('' || state?.uuid);
    const [dateRange, setDateRange] = useState('');
    const generateDisabled = date.length <= 1 || Object.keys(assetSelectionList).length <= 1;
    const [dropDownLoading, setDropDownLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [minDateRange, setMinDateRange] = useState(new DateObject().subtract(1, 'year'));
    const [maxDateRange, setMaxDateRange] = useState(new Date());

    const assetPerformanceNavigationList = [
        {
            text: 'Asset Performance', value: 1, path: '/insights/assetPerformance', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.insights?.assetPerformance,
        },
        // {
        //     text: 'IOrail', value: 2, path: '/insights/IOrail', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.insights?.iorail,
        // },
        // {
        //     text: 'IOroad', value: 3, path: '/insights/IOroad', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.insights?.ioroad,
        // },
        // {
        //     text: 'Audits', value: 4, path: '/insights/audits', enabled: true,
        // },
        {
            text: 'O/D Analytics', value: 5, path: '/insights/odAnalytics', enabled: (apiResponse?.role === 'Agency Administrator' || apiResponse?.role === 'Agency Owner') ? true : apiResponse?.module?.insights?.odAnalytics,
        },
    ];

    const APICalledTelemetryLogReport = async () => {
        const fromTime = moment(new Date(date[0])).tz(userTimezone || 'Asia/Kuala_Lumpur').startOf('day').format();
        const toTime = moment(new Date(date[1])).tz(userTimezone || 'Asia/Kuala_Lumpur').endOf('day').format();
        const deviceOid = assetSelectionList.uuid;
        const fuelSource = assetSelectionList?.fuelSource ?? null;
        const mileageSource = assetSelectionList?.mileageSource ?? null;
        try {
            const result = await APIGetV3TelemetryLog(setLoading, token, agencyID, fromTime, toTime, deviceOid, fuelSource, mileageSource);
            if (result.data.status === 200 && result.data.data.length !== 0) {
                const { data } = result.data;
                const report = data && data.length > 0 ? data.map((x) => ({
                    sampleState: x.sampleState,
                    latitude: x.latitude,
                    longitude: x.longitude,
                    address: {
                        localDisplay: x['address.localDisplay'],
                        road: x['address.road'],
                        suburb: x['address.suburb'],
                        city: x['address.city'],
                        state: x['address.state'],
                        postcode: x['address.postcode'],
                        country: x['address.country'],
                        countryCode: x['address.countryCode'],
                    },
                    kmhDifference: x.kmhDifference,
                    kmh: x.kmh,
                    prevKmh: x.kmh,
                    motionInference: x.motionInference,
                    timestamp: x.timestamp,
                    operatorName: x.operatorName,
                    battVolt: x.battVolt,
                    battVoltPerc: x.battVoltPerc,
                    dcVolt: x.dcVolt,
                    dcVoltPerc: x.dcVoltPerc,
                    prevDcVoltPerc: x.prevDcVoltPerc,
                    avgDcVoltPerc: x.avgDcVoltPerc,
                    odometer: x.odometer,
                    _id: x._id,
                    fuelLevelLitre: x.fuelLevelLitre,
                    fuelLevelPercentage: x.fuelLevelPercentage,
                })) : [];
                navigate('/insights/assetPerformance/TelemetryLog', {
                    replace: true,
                    state: {
                        assetDetails: assetSelectionList,
                        fromTime: date[0].toString(),
                        toTime: date[1].toString(),
                        telemData: report,
                    },
                });
                setLoading(false);
            } else if (result.data.status === 200 && result.data.totalData !== 0) {
                setShowModal(true);
                setLoading(false);
            } else {
                setIncorrectCredentials(true);
                setLoading(false);
            }
        } catch (err) {
            setIncorrectCredentials(true);
            setLoading(false);
        }
    };

    const generateReport = () => {
        APICalledTelemetryLogReport();
    };

    const reset = () => {
        setDateRange('');
        setMinDateRange(null);
        setMaxDateRange(new Date());
        setInputValue('Select Dates');
        setIncorrectCredentials(false);
        setDate([]);
        setAssetSelectionList({});
        setSelectedOption(null);
        setInputSelectonText('');
    };

    const APICalledSearchAssetNGroup = async (inputText) => {
        const params = {
            pageNumber: 1,
            totalPerPage: 100,
            searchKey: inputText,
        };
        try {
            const result = await APIGetDevices(setLoadAsset, token, agencyID, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                const asset = data && data.length > 0 ? data.map((x) => ({
                    uuid: x.oid,
                    id: x.assetName,
                    name: x.deviceSerial ? x.deviceSerial : '',
                    pairId: x.oid,
                    pairedAssets: [],
                    fuelSource: x.fuelSource,
                    mileageSource: x.mileageSource,
                })) : [];
                const sortCompare = asset.length > 0 ? _.sortBy(asset, 'name') : [];
                setListAsset(sortCompare);
                if (state?.uuid) {
                    setSelectedOption(asset.findIndex((e) => e.uuid === uuid));
                    setAssetSelectionList(asset.find((e) => e.uuid === uuid));
                }
                setLoadAsset(false);
            } else {
                setListAsset([]);
                setLoadAsset(false);
            }
        } catch (err) {
            setLoadAsset(false);
        }
    };

    // this useEffect for call the searchAsset API called. Interval for 0.5 sec
    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout); // this to clear time out
        }
        setSearchTimeout( // to buffer the search by 0.5 second
            setTimeout(() => {
            // APICalledSearchAssetNGroup(); // call the api
            APICalledSearchAssetNGroup(inputSelectonText);
        }, 500),
        );
        return () => clearTimeout(searchTimeout); // this also to clear time out
    }, [inputSelectonText]);

    // this useEffect for onPageLoad
    useEffect(() => {
        if (token) {
            APICalledSearchAssetNGroup();
        }
        window.history.replaceState({}, document.title);
        setNavigtionClick(1);
        setNavigationState('insights');
    }, []);

    useEffect(() => {
        APICalledSearchAssetNGroup('');
        setDropDownLoading(false);
    }, [dropDownLoading]);

    return (
        <div className="asset-performance-container">
            <ListNavigationTab list={assetPerformanceNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
            <div className="asset-performance-header">
                <Title title="Asset Performance" subtitle="Reports and insights to monitor and analyse asset / device performance" titleSize="1.875rem" subtitleSize="1rem" lineHeight="2.375rem" subtitleLineHeight="1.5rem" />
                <div className="asset-performance-tabs">
                    <ConfigTab color="#FFFF" types={navigationList} module="assetPerformance" navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
                </div>
            </div>
            <div className="asset-performance-body">
                <div className="asset-performance-body-title">
                    <Title title="Retrieve historical data for an asset / device" subtitle="Begin by inputting search parameters below" titleSize="1.125rem" subtitleSize="0.875rem" lineHeight="1.75rem" subtitleLineHeight="1.25rem" />
                    <div className="asset-performance-button-group">
                        <LiveMapButton label="Reset" width="4.4375rem" onClickFunction={() => reset()} />
                        <CreateWithIcon module="assetPerformance" label=" Generate Report" buttonWidth="9.125rem" onClickFunction={(e) => APICalledTelemetryLogReport()} disable={generateDisabled} loading={loading} />
                        {/* <CreateWithIcon module="assetPerformance" label=" Generate Report" buttonWidth="9.125rem" onClickFunction={(e) => setShowModal(!showModal)} disable={generateDisabled} loading={loading} /> */}
                    </div>
                </div>
                <div className="asset-performance-body-form">
                    <div className="timeline-container">
                        <div className="timeline-title-subtitle-container">
                            <div className="timeline-text">
                                Timeline
                            </div>
                            <div className="timeline-subtitle">
                                Data export will be sent to your email if the timeline exceeds the past 7 days.
                            </div>
                        </div>
                        <DateRangePicker date={date} setDate={setDate} inputValue={inputValue} setInputValue={setInputValue} minDateRange={minDateRange} setMinDateRange={setMinDateRange} maxDateRange={maxDateRange} setMaxDateRange={setMaxDateRange} setApplyDatePicker={() => {}} dateRange={dateRange} setDateRange={setDateRange} />
                    </div>
                    <div className="asset-device-container">
                        <div className="timeline-text">
                            Asset / Device
                        </div>
                        <Autocomplete component="AssetPerformance" autocompleteComponent="AssetSearch" list={listAsset} setSelectionList={setAssetSelectionList} selectionList={assetSelectionList} text="Select asset / device" isImage isAgencySelector={false} inputValue={inputSelectonText} stateInputValue={setInputSelectonText} selectedOption={selectedOption} setSelectedOption={setSelectedOption} dropDownLoading={dropDownLoading} setDropDownLoading={setDropDownLoading} loadAsset={loadAsset} />
                    </div>
                </div>
            </div>
            {incorrectCredentials && (
                <div className="notification">
                    <SnackBar state={incorrectCredentials} setState={setIncorrectCredentials} title="Unable to Generate Report" subtitile="No data is found for the current search. Please try again with different search parameters." module="AssetPerformance" action="AssetPerformance" />
                </div>
            )}
            {showModal && (
                <div className="modal-container-bg-topleft">
                    <Modal switchModalShow={showModal} setSwitchModalShow={setShowModal} module="GenerateReport" resetBtn={reset} />
                </div>
            )}
        </div>
    );
};

export default AssetPerformance;
