import axios from 'axios';

const url = process.env.REACT_APP_RESTAPI;
const urlV3 = process.env.REACT_APP_RESTAPI_V3;
const xApiKey = process.env.REACT_APP_XAPIKEY;

// For loading animation purposes
const delay = (ms) => new Promise((resolve) => (setTimeout(resolve, ms)));

export const APIGetPayload = async (setLoading, token, agencyId, pairId) => {
    setLoading(true);
    const result = await axios.get(`${url}/payload`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            pairId: pairId,
        },
    }).catch((error) => error);
    return result;
};

export const APIGetSearchAssetnAssetGroup = async (token, setLoading, agencyId, searchType, searchKey, selectedIds) => {
    setLoading(true);
    const result = await axios.get(`${url}/searchAssetsnGroups`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            searchType: searchType,
            searchKey: searchKey,
            selectedIds: selectedIds,
        },
    }).catch((error) => error);
    return result;
};

export const APISendTeltonikaCommand = async (commandData, token, agencyId, setLoading) => {
    setLoading(true);
    const result = await axios.post(`${url}/teltonikaCommand`, commandData, {
        headers: {
            'x-api-key': xApiKey,
            Authorization: token,
            'agency-id': agencyId,
        },
    }).catch((error) => error.response.status);
    return result;
};

export const APIGetCountries = async (setLoading) => {
    setLoading(true);
    const result = await axios.get('https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code').catch((error) => error);
    return result;
};

export const APIGetDevices = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/devices`, {
        headers: {
            'x-api-key': xApiKey,
            Authorization: token,
            'agency-id': agencyId,
        },
        params: params,
    }).catch((error) => error.response.status);
    return result;
};

export const APIGetV3Payload = async (setLoading, token, agencyId, pairId) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/payload`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            deviceOid: pairId,
        },
    }).catch((error) => error);
    return result;
};

export const APIGetV3TelemetryLog = async (setLoading, token, agencyId, fromTime, toTime, deviceOid, fuelSource, mileageSource) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/telemetryLogReport`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            fromTime: fromTime,
            toTime: toTime,
            deviceOid: deviceOid,
            fuelSource: fuelSource,
            mileageSource: mileageSource,
        },
    }).catch((error) => error);
    return result;
};

export const APIGetV3TeamMembers = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/team`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: params,
    }).catch((error) => error);
    return result;
};

export const APIGetUsers = async (setLoading, token, agencyId) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/account`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
    }).catch((error) => error);
    return result;
};

export const APIGetAgencies = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/agencies`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: params,
    }).catch((error) => error);
    await delay(500);
    return result;
};

export const APIGetGeofence = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/geofences`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: params,
    }).catch((error) => error);
    return result;
};

export const APIGetAssetUtilisation = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/assetUtilisation`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        timeout: 120000,
        params: params,
    }).catch((error) => error);
    return result;
};

export const APIGetAssetUtilisationPerc = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/assetUtilisationPerc`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        timeout: 120000,
        params: params,
    }).catch((error) => error);
    return result;
};

export const APIGetTotalIngestedPayloads = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/ingestedPayloads`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        // timeout: 12000,
        params: params,
    }).catch((error) => error);
    return result;
};

export const APIGetTotalDevices = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/totalDevices`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        timeout: 120000,
        params: params,
    }).catch((error) => error);
    return result;
};

export const APIGetActiveDevicePerc = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/activeDevicesPercentage`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        // timeout: 12000,
        params: params,
    }).catch((error) => error);
    return result;
};

export const APIGetTripReport = async (setLoading, token, agencyId, fromTime, toTime, deviceOid) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/trip`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: {
            fromTime: fromTime,
            toTime: toTime,
            deviceOid: deviceOid,
        },
    }).catch((error) => error);
    return result;
};

export const APIGetSIMDetails = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/sims`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: params,
    }).catch((error) => error);
    return result;
};

export const APIGetAlerts = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/alerts`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: params,
    }).catch((error) => error);
    return result;
};

export const APIGetAlertPolicies = async (setLoading, token, agencyId, params) => {
    setLoading(true);
    const result = await axios.get(`${urlV3}/alertPolicies`, {
        headers: {
            Authorization: token,
            'x-api-key': xApiKey,
            'agency-id': agencyId,
        },
        params: params,
    }).catch((error) => error);
    return result;
};
