import React from 'react';
import './inputtext.css';

export default function InputText(props) {
    const {
        placeholder, onChange, value, width, icon, disabled, title, style,
    } = props;

    return (
        <div
          className="text-input"
          style={{
            position: 'relative',
            display: 'inline-block',
            ...style,
          }}
        >
            {title && (
                <div
                  className="input-title"
                  style={{
                    position: 'absolute',
                    top: '-0.65rem',
                    left: '0.75rem',
                    background: 'white',
                    padding: '0 0.25rem',
                    fontSize: '0.75rem',
                    color: disabled ? 'rgba(102, 112, 133, 0.6)' : '#667085',
                    fontWeight: '400',
                    zIndex: 1,
                  }}
                >
                    {title}
                </div>
            )}
            {icon && (
                <img
                  src={icon}
                  alt="icon"
                  className="input-icon"
                  style={{ opacity: disabled ? 0.6 : 1 }}
                />
            )}
            <input
              style={{
                width: width,
                paddingLeft: icon ? '2.5rem' : '0.875rem',
                color: disabled ? 'rgba(102, 112, 133, 0.6)' : '#101828',
                backgroundColor: 'transparent',
                cursor: disabled ? 'not-allowed' : 'text',
                border: '1px solid #D0D5DD',
                outline: 'none',
                transition: 'border-color',
              }}
              className="input-text-container"
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              disabled={disabled}
              onFocus={(e) => {
                if (!disabled) {
                    e.target.style.border = '1px solid #667085';
                }
              }}
              onBlur={(e) => {
                if (!disabled) {
                    e.target.style.border = '1px solid #D0D5DD';
                }
              }}
              onMouseEnter={(e) => {
                if (!disabled) {
                    e.target.style.border = '1px solid #667085';
                }
              }}
              onMouseLeave={(e) => {
                if (!disabled && e.target !== document.activeElement) {
                    e.target.style.border = '1px solid #D0D5DD';
                }
              }}
            />
        </div>
    );
}
