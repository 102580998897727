/* eslint-disable */
import React, { useState } from 'react';
import moment from 'moment';
import Checkbox from '../checkbox/Checkbox';
import EnterIcon from './img/enter.svg';
import ColoredLine from '../coloredLine/ColoredLine';
import UnreadIcon from './img/unread.svg';
import GeofenceAlertIcon from './img/geofenceAlert.svg';
import UnpluggedAlertIcon from './img/unpluggedStatus.svg';
import AssetStatusAlertIcon from './img/assetStatus.svg';
import OverspeedingAlertIcon from './img/overspeeding.svg';
import UpIcon from './img/upIcon.svg';
import DownIcon from './img/downIcon.svg';
import './notificationList.css';

const NotificationList = (props) => {
    const {
        data, selectedCheckboxes, setSelectedCheckboxes, handleChecked,
    } = props;
    const [expandedNotifications, setExpandedNotifications] = useState({});

    const checkAlertType = (type) => {
        switch (type) {
            case 101:
                return 'Geofence Alert';
            case 102:
                return 'Unplugged Status Alert';
            case 103:
                return 'Asset Status Alert';
            case 104:
                return 'Overspeeding Alert';
            default:
                return null;
        }
    };

    const alertIcon = {
        101: {
            icon: GeofenceAlertIcon,
            alt: 'geofence-icon',
        },
        102: {
            icon: UnpluggedAlertIcon,
            alt: 'unplugged-icon',
        },
        103: {
            icon: AssetStatusAlertIcon,
            alt: 'asset-status-icon',
        },
        104: {
            icon: OverspeedingAlertIcon,
            alt: 'overspeeding-icon',
        },
    };

    // Toggle expanded/collapsed state
    const toggleExpand = (oid) => {
        setExpandedNotifications((prevState) => ({
            ...prevState,
            [oid]: !prevState[oid],
        }));
    };

    return (
        <div className="notificationList-body">
            {data && data.map((x, index) => {
                const { icon, alt } = alertIcon[x.type] || {
                    icon: EnterIcon,
                    alt: 'default-icon',
                };
                return (
                    <React.Fragment key={index}>
                        <div className="notification-feed">
                            <Checkbox
                              checked={!!selectedCheckboxes[x.oid]}
                              width="20px"
                              height="20px"
                              onClick={() => handleChecked(x.oid)}
                              value={x.oid}
                            />
                            <div className="notificationFeed-container">
                                <div className="notificationFeed-body">
                                    <div className="notificationFeed-body-header">
                                        {x.read === false && (
                                            <img
                                              src={UnreadIcon}
                                              alt="unread-icon"
                                              loading="lazy"
                                              className="unread-icon"
                                            />
                                        )}
                                        <img src={icon} alt={alt} loading="lazy" />
                                        <div className="notification-type">
                                            {checkAlertType(x.type)}
                                        </div>
                                        <div className="notification-time">
                                            {moment(x.timestamp).fromNow()}
                                        </div>
                                        <div
                                            className="expand-icon"
                                            onClick={() => toggleExpand(x.oid)}
                                            style={{ cursor: 'pointer' }}
                                        ></div>
                                    </div>
                                    <div className="notificationFeed-body-content">
                                        <div className="notificationFeed-body-text-container">
                                            <div
                                                className="notificationFeed-body-text message"
                                                onClick={() => toggleExpand(x.oid)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {x.message}
                                                <img
                                                    src={expandedNotifications[x.oid] ? UpIcon : DownIcon}
                                                    alt={expandedNotifications[x.oid] ? 'up-icon' : 'down-icon'}
                                                    loading="lazy"
                                                    className="up-down-icon"
                                                />
                                            </div>
                                            {expandedNotifications[x.oid] && (
                                                <div className="expanded-content">
                                                    {x.type === 101 ? (
                                                        // Geofence Alert Body
                                                        <>
                                                            {x.policyName && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Alert Policy: ${x.policyName}`}
                                                                </div>
                                                            )}
                                                            {x.areaName && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Area Name: ${x.areaName}`}
                                                                </div>
                                                            )}
                                                            {x.geofenceName && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Geofence Name: ${x.geofenceName}`}
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : x.type === 102 ? (
                                                        // Unplugged Status Alert Body
                                                        <>
                                                            {x.deviceSerial && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Device Serial: ${x.deviceSerial}`}
                                                                </div>
                                                            )}
                                                            {x.assetName && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Asset Name: ${x.assetName}`}
                                                                </div>
                                                            )}
                                                            {x.assetType && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Asset Type: ${x.assetType}`}
                                                                </div>
                                                            )}
                                                            {x.agencyName && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Agency Name: ${x.agencyName}`}
                                                                </div>
                                                            )}
                                                            {x.areaName && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Area Name: ${x.areaName}`}
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : x.type === 102 ? (
                                                        // Asset Status Alert Body (Type 103)
                                                        <>
                                                            {x.assetName && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Asset Name: ${x.assetName}`}
                                                                </div>
                                                            )}
                                                            {x.assetTrigger && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Asset Trigger: ${x.assetTrigger}`}
                                                                </div>
                                                            )}
                                                            {/* {x.assetStatus && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Asset Status: ${x.assetStatus}`}
                                                                </div>
                                                            )} */}
                                                            {x.policyName && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Alert Policy: ${x.policyName}`}
                                                                </div>
                                                            )}
                                                            {x.lastSeenLocation && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Last Seen Location: ${x.lastSeenLocation}`}
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (
                                                        // Oveerspeding Alert Body (Type 104)
                                                        <>
                                                            {x.assetName && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Asset Name: ${x.assetName}`}
                                                                </div>
                                                            )}
                                                            {x.assetTrigger && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Asset Trigger: ${x.assetTrigger}`}
                                                                </div>
                                                            )}
                                                            {x.assetStatus && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Asset Status: ${x.assetStatus}`}
                                                                </div>
                                                            )}
                                                            {x.policyName && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Alert Policy: ${x.policyName}`}
                                                                </div>
                                                            )}
                                                            {x.lastSeenLocation && (
                                                                <div className="notificationFeed-body-text">
                                                                    {`Last Seen Location: ${x.lastSeenLocation}`}
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    <div className="notificationFeed-body-text">
                                                        {`Agency Local Time: ${moment(x.timestamp).format(
                                                            'DD MMM YYYY h:mm a'
                                                        )}`}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ColoredLine />
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default NotificationList;
