import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import styled from 'styled-components';

const StyledInput = styled(TextField)`
& .MuiOutlinedInput-notchedOutline {
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  }
  & .MuiFormHelperText-root {
    margin-left: 0px;
  }
`;

export default function TextArea(props) {
    const { descriptionValue, setDescriptionValue, width = '512px' } = props;

    const CHARACTER_LIMIT = 400;

    const [characterCount, setCharacterCount] = useState(400);

    const handleChange = (event) => {
      setDescriptionValue(event.target.value);
    };

    const getCharacterCountLeft = () => {
        const count = CHARACTER_LIMIT - descriptionValue.length;
        return setCharacterCount(count);
    };

    useEffect(() => {
        getCharacterCountLeft();
    }, [descriptionValue]);

    return (
        <div>
          {
            descriptionValue === null ? (
              <StyledInput
                placeholder="Add a brief description..."
                sx={{
                  mt: 0,
                  width: width,
                }}
                multiline
                rows={4}
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                }}
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                helperText={`${characterCount} characters left`}
              />
            ) : (
              <StyledInput
                defaultValue={descriptionValue}
                sx={{
                  mt: 0,
                  width: width,
                }}
                multiline
                rows={4}
                inputProps={{
                  maxLength: CHARACTER_LIMIT,
                }}
                margin="normal"
                variant="outlined"
                onChange={handleChange}
                helperText={`${characterCount} characters left`}
              />
            )
          }
        </div>
    );
}
