/* eslint-disable consistent-return */
import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import _, { set } from 'lodash';
import { LinearProgress, linearProgressClasses } from '@mui/material';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useOutletContext } from 'react-router';
import { DateObject } from 'react-multi-date-picker';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import Title from '../../../component/title/Title';
import Autocomplete from '../../../component/autocomplete/Autocomplete';
import { downloadCSV } from '../../../config/function/exportCSV';
import {
    CreateWithIcon,
    LiveMapButton,
} from '../../../component/buttonComponent/ButtonComponent';
import SnackBar from '../../../component/snackbar/Snackbar';
import DateRangePicker from '../../../component/dateRangePicker/DateRangePicker';
import { APIGetAlerts } from '../../../config/restAPI/FetchAPI';
import { useApiData } from '../../../config/reducer/Modules';
import './exportAlerts.css';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 16,
    borderRadius: 5,
    [`&.${linearProgressClasses.root}`]: {
      height: '8px',
    },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#EAECF0',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#365A6D',
    },
  }));

// For pass value to API
const ALERT_TYPE_MAP = {
    101: 'Geofence',
    102: 'Unplug',
    103: 'Asset Status',
    104: 'Overspeeding',
};

// For display value
const ALERT_TYPE_DISPLAY_MAP = {
    101: 'Geofence',
    102: 'Unplugged Status',
    103: 'Asset Status',
    104: 'Overspeeding',
};

// For geofence display value
const GEOFENCE_STATE = {
    Enter: 'Entered',
    Leave: 'Exited',
};

let exportReport;

const ExportAlerts = (props) => {
    const { state } = useLocation();
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const userTimezone = Cookies.get('timezone') || 'Asia/Kuala_Lumpur';
    const { apiResponse } = useApiData();
    const [loadAsset, setloadAlert] = useState(false); // for loader
    const navigate = useNavigate();
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState] = useOutletContext();
    const [listAlert, setlistAlert] = useState([]); // this is for list of alert type
    const [alertTypeSelectionList, setalertTypeSelectionList] = useState({}); // this for alert type selection
    const [inputSelectonText, setInputSelectonText] = useState('');
    const [incorrectCredentials, setIncorrectCredentials] = useState(false); // this is for loading state
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState([]);
    const [inputValue, setInputValue] = useState('Select Dates');
    const [searchTimeout, setSearchTimeout] = useState(null);// buffer when do search
    const [selectedOption, setSelectedOption] = useState(null);
    const [type, setAlertType] = useState('' || state?.type);
    const [dateRange, setDateRange] = useState('');
    const generateDisabled = date.length <= 1 || Object.keys(alertTypeSelectionList).length <= 1;
    const [dropDownLoading, setDropDownLoading] = useState(false);
    const [minDateRange, setMinDateRange] = useState(new DateObject().subtract(1, 'year'));
    const [maxDateRange, setMaxDateRange] = useState(new Date());
    const [progress, setProgress] = useState(0);
    const [isExporting, setIsExporting] = useState(false);
    // State for filtered alert policies
    const [filteredAlertPolicies, setFilteredAlertPolicies] = useState([]);
    const [policySelectionList, setPolicySelectionList] = useState({});
    const [policyInputValue, setPolicyInputValue] = useState('');
    const [policySelectedOption, setPolicySelectedOption] = useState(null);

    const notificationNavigationList = [
        {
            text: 'Notifications History', value: 1, path: '/notifications/history', enabled: true,
        },
        {
            text: 'Alert Configurations', value: 2, path: '/notifications/configuration', enabled: true,
        },
        {
            text: 'Export Alerts', value: 3, path: '/notifications/exportalerts', enabled: true,
        },
    ];

    const fetchAlertData = async () => {
        const startDate = moment(new Date(date[0])).tz(userTimezone || 'Asia/Kuala_Lumpur').startOf('day').format();
        const endDate = moment(new Date(date[1])).tz(userTimezone || 'Asia/Kuala_Lumpur').endOf('day').format();
        const { alertType, alertDisplayType } = alertTypeSelectionList;
        try {
            setLoading(true);
            const exportAlert = true;
            const params = {
                startDate,
                endDate,
                alertType,
                exportAlert,
            };
            if (alertType === ALERT_TYPE_DISPLAY_MAP[101] || alertType === ALERT_TYPE_DISPLAY_MAP[103] || alertType === ALERT_TYPE_DISPLAY_MAP[104]) {
                params.alertPolicy = policySelectionList.alertPolicy;
            }
            const result = await APIGetAlerts(setLoading, token, agencyID, params);
            if (result.data.status === 200 && result.data.data.length !== 0) {
                setProgress(20);
                const { data } = result.data;
                const report = data.map((x) => {
                    const dynamicFields = {};
                    if (x.type === 101) {
                        dynamicFields['Alert Type'] = ALERT_TYPE_MAP[x.type];
                        dynamicFields['Alert Policy'] = x.policyName;
                        dynamicFields['Area Name'] = x.areaName;
                        dynamicFields['Alert Trigger'] = GEOFENCE_STATE[x.geofenceTrigger];
                    }
                    if (x.type === 102) {
                        dynamicFields['Asset Type'] = x.assetType;
                        dynamicFields['Agency Name'] = x.agencyName;
                        dynamicFields['Alert Trigger'] = x.plugTrigger || '';
                        dynamicFields['Last Seen Location'] = x.areaName || 'Unknown';
                        dynamicFields['Alert Type'] = ALERT_TYPE_DISPLAY_MAP[x.type];
                    }
                    if (x.type === 103) {
                        dynamicFields['Alert Type'] = ALERT_TYPE_MAP[x.type];
                        dynamicFields['Alert Policy'] = x.policyName;
                        dynamicFields['Asset Policy Status'] = x.assetStatus.motionState;
                        dynamicFields['Alert Trigger'] = x.assetTrigger;
                        dynamicFields['Last Seen Location'] = x.location;
                    }
                    if (x.type === 104) {
                        dynamicFields['Speed (km/h)'] = String(x.speed);
                        dynamicFields['Alert Type'] = ALERT_TYPE_MAP[x.type];
                        dynamicFields['Alert Policy'] = x.policyName;
                        dynamicFields['Last Seen Location'] = x.location;
                    }
                    const reportItem = {
                        'Alert Message': x.message,
                        'Device Serial': x.deviceSerial,
                        'Asset Name': x.assetName,
                        ...dynamicFields,
                        'Agency Local Time': moment(x.timestamp).tz(userTimezone).format('h:mm A D/M/YYYY'),
                    };

                    return reportItem;
                });
                const exportDate = `${moment(startDate).format('YYYY-MM-DD')}_${moment(endDate).format('YYYY-MM-DD')}`;
                const filename = `alerts_history_${alertDisplayType}_${exportDate}.csv`;
                exportReport = { report, filename };
                setLoading(false);
            } else if (result.data.status === 200 && result.data.totalData !== 0) {
                setLoading(false);
                setIncorrectCredentials(true); // Display error message if no data
                setIsExporting(false); // Stop exporting
            } else {
                setLoading(false);
                setIncorrectCredentials(true);
                setIsExporting(false);
            }
        } catch (err) {
            console.log('Failed to export csv', err);
            setIncorrectCredentials(true);
            setIsExporting(false);
            setLoading(false);
        }
    };

    // Function to fetch alert policies based on selected alert type
    const fetchAlertPolicies = async (alertType) => {
        try {
            setDropDownLoading(true);
            const exportAlert = true;
            const params = {
                pageNumber: 1,
                totalPerPage: 100,
                alertType,
                exportAlert,
            };
            const result = await APIGetAlerts(setloadAlert, token, agencyID, params);
            if (result.data.status === 200) {
                const { data } = result.data;
                const policies = data.map((item) => ({
                    id: item.oid,
                    alertPolicy: item.policyName,
                    alertDisplayType: item.policyName,
                }));

            // Filter unique policies
            const uniquePolicies = _.uniqBy(policies, 'alertPolicy');

            setFilteredAlertPolicies(uniquePolicies);
            } else {
                setFilteredAlertPolicies([]);
            }
            setDropDownLoading(false);
        } catch (err) {
        setFilteredAlertPolicies([]);
        setDropDownLoading(false);
        }
    };

    const reset = () => {
        setDateRange('');
        setMinDateRange(new DateObject().subtract(1, 'year'));
        setMaxDateRange(new Date());
        setInputValue('Select Dates');
        setIncorrectCredentials(false);
        setDate([]);
        setalertTypeSelectionList({});
        setPolicySelectionList({});
        setSelectedOption(null);
        setPolicySelectedOption(null);
        setInputSelectonText('');
        setPolicyInputValue('');
        setFilteredAlertPolicies([]);
    };

    const handleAlertTypeSelection = (selected) => {
        setalertTypeSelectionList(selected);
        fetchAlertPolicies(selected.alertType);
    };

    const handlePolicySelection = (selected) => {
        setPolicySelectionList(selected);
        setIncorrectCredentials(false);
    };

    const APICalledSearchAlert = async (inputText) => {
        const params = {
            pageNumber: 1,
            totalPerPage: 100,
            searchKey: inputText,
        };

        // Hardcoded alert types
        const hardcodedAlertTypes = [101, 102, 103, 104];

        try {
            const result = await APIGetAlerts(setloadAlert, token, agencyID, params);

            // Create hardcoded alerts regardless of API response
            const alert = hardcodedAlertTypes.map((alertValue) => ({
                uuid: `hardcoded-${alertValue}`, // Generate a unique ID for each hardcoded alert
                id: `hardcoded-${alertValue}`,
                name: '', // You can hardcode a name if needed
                pairId: `hardcoded-${alertValue}`,
                alertMessage: `This is a hardcoded alert for ${alertValue}`, // Custom message
                alertType: ALERT_TYPE_MAP[alertValue],
                alertDisplayType: ALERT_TYPE_DISPLAY_MAP[alertValue], // Use the same value for display
            }));

            // Filter to get unique alert types (not strictly necessary here since we're hardcoding)
            const uniqueAlerts = Object.values(
                alert.reduce((acc, item) => {
                    if (!acc[item.alertType]) {
                        acc[item.alertType] = item;
                    }
                    return acc;
                }, {}),
            );

            // Sort the unique alerts by alertDisplayType
            const sortedAlerts = _.sortBy(uniqueAlerts, 'alertDisplayType');
            setlistAlert(sortedAlerts);

            if (state?.type) {
                setSelectedOption(alert.findIndex((e) => e.alertDisplayType === type));
                setalertTypeSelectionList(alert.find((e) => e.alertDisplayType === type));
            }
            setloadAlert(false);
        } catch (err) {
            setloadAlert(false);
        }
    };

    // this useEffect for call the searchAsset API called. Interval for 0.5 sec
    useEffect(() => {
        if (searchTimeout) {
            clearTimeout(searchTimeout); // this to clear time out
        }
        setSearchTimeout( // to buffer the search by 0.5 second
            setTimeout(() => {
            // APICalledSearchAlert(); // call the api
            APICalledSearchAlert(inputSelectonText);
        }, 500),
        );
        return () => clearTimeout(searchTimeout); // this also to clear time out
    }, [inputSelectonText]);

    // this useEffect for onPageLoad
    useEffect(() => {
        if (token) {
            APICalledSearchAlert();
        }
        window.history.replaceState({}, document.title);
        setNavigtionClick(3);
    }, []);

    useEffect(() => {
        APICalledSearchAlert('');
        setDropDownLoading(false);
    }, [dropDownLoading]);

    useEffect(() => {
        let timer;
        if (incorrectCredentials) {
          timer = setTimeout(() => {
            setIncorrectCredentials(false); // Automatically hide the snackbar after 5 seconds
          }, 5000);
        }
        return () => {
          clearTimeout(timer); // Cleanup the timer on unmount or when the snackbar state changes
        };
      }, [incorrectCredentials]);

    const setVisible = () => {
        setIsExporting(true);
        fetchAlertData();
    };

    useEffect(() => {
        if (isExporting === true && progress < 100) {
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress + 20));
            }, 500);
            return () => {
                if (progress === 80) {
                 const { report, filename } = exportReport;
                 downloadCSV(report, filename);
                }
                clearInterval(timer);
            };
        }
  }, [progress]);

    return (
        <div className="export-alerts-container">
            <ListNavigationTab list={notificationNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
            <div className="export-alerts-header">
                <Title title="Export Alerts" subtitle="Easily export your notification alerts by selecting a specific date range, selecting alert type and policy ensuring you have all the information you need in one place" titleSize="1.875rem" subtitleSize="1rem" lineHeight="2.375rem" subtitleLineHeight="1.5rem" />
            </div>
            <div className="export-alerts-body">
                <div className="export-alerts-body-title">
                    <Title title="Retrieve historical notification alerts" subtitle="Begin by inputting search parameters below" titleSize="1.125rem" subtitleSize="0.875rem" lineHeight="1.75rem" subtitleLineHeight="1.25rem" />
                    <div className="export-alerts-button-group">
                        <LiveMapButton label="Reset" width="4.4375rem" onClickFunction={() => reset()} />
                        <CreateWithIcon module="assetPerformance" label="Export" buttonWidth="9.125rem" disable={generateDisabled} loading={loading} onClickFunction={(e) => setVisible()} />
                    </div>
                </div>
                <div className="export-alerts-body-form">
                    <div className="timeline-container">
                        <div className="timeline-title-subtitle-container">
                            <div className="timeline-text">
                                Timeline
                            </div>
                        </div>
                        <DateRangePicker date={date} setDate={setDate} inputValue={inputValue} setInputValue={setInputValue} minDateRange={minDateRange} setMinDateRange={setMinDateRange} maxDateRange={maxDateRange} setMaxDateRange={setMaxDateRange} setApplyDatePicker={() => {}} dateRange={dateRange} setDateRange={setDateRange} />
                    </div>
                    <div className="asset-device-container">
                        <div className="timeline-text">
                            Alert Type
                        </div>
                        <Autocomplete
                          component="ExportAlert"
                          autocompleteComponent="AlertType"
                          list={listAlert}
                          setSelectionList={handleAlertTypeSelection}
                          selectionList={alertTypeSelectionList}
                          text="Select Alert Type"
                          isAgencySelector={false}
                          inputValue={inputSelectonText}
                          stateInputValue={setInputSelectonText}
                          selectedOption={selectedOption}
                          setSelectedOption={setSelectedOption}
                          dropDownLoading={dropDownLoading}
                          setDropDownLoading={setDropDownLoading}
                          loadAsset={loadAsset}
                          contentEditable={false}
                        />
                    </div>
                    {(alertTypeSelectionList.alertType === ALERT_TYPE_DISPLAY_MAP[101] || alertTypeSelectionList.alertType === ALERT_TYPE_DISPLAY_MAP[103] || alertTypeSelectionList.alertType === ALERT_TYPE_DISPLAY_MAP[104]) && (
                        <div className="asset-device-container">
                            <div className="timeline-text">
                                Alert Policy
                            </div>
                            <Autocomplete
                              component="ExportAlert"
                              autocompleteComponent="AlertPolicy"
                              list={filteredAlertPolicies}
                              setSelectionList={handlePolicySelection}
                              selectionList={policySelectionList}
                              text="Select Alert Policy"
                              isAgencySelector={false}
                              inputValue={policyInputValue}
                              stateInputValue={setPolicyInputValue}
                              selectedOption={policySelectedOption}
                              setSelectedOption={setPolicySelectedOption}
                              dropDownLoading={dropDownLoading}
                              setDropDownLoading={setDropDownLoading}
                              loadAsset={loadAsset}
                              contentEditable={false}
                            />
                        </div>
                    )}
                </div>
            </div>
            {incorrectCredentials && (
                <div className="notification">
                    <SnackBar autoHide state={incorrectCredentials} setState={setIncorrectCredentials} title="Unable to Export Alerts History" subtitile="No data is found for the current search. Please try again with different search parameters." module="AssetPerformance" action="AssetPerformance" />
                </div>
            )}
            {isExporting && progress < 100 && (
                <div className="notification">
                    <SnackBar state={isExporting} setState={setIsExporting} title="Exporting Data" deleteSubtitle module="TelemetryLog" action="info">
                        <div className="exportAlerts-notification-body">
                            Your download will begin shortly
                            <div className="exportAlerts-progress-bar">
                                <BorderLinearProgress variant="determinate" value={progress} style={{ width: '280px' }} />
                                <div className="exportAlerts-progress-bar-text">
                                    {progress}
                                    % processed...
                                </div>
                            </div>
                        </div>
                    </SnackBar>
                </div>
            )}
        </div>
    );
};

export default ExportAlerts;
