/* eslint-disable react/jsx-one-expression-per-line, no-nested-ternary */
import React, { useState, useContext, useEffect } from 'react';
import { ThemeProvider, createTheme, CircularProgress } from '@mui/material';
import _cloneDeep from 'lodash.clonedeep';
import Cookies from 'js-cookie';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import './NotificationHistory.css';
import NotifConfigTab from '../../../component/notificationConfigTab/notificationConfigTab';
import ListNavigationTab from '../../../component/listNavigationTab/ListNavigationTab';
import Title from '../../../component/title/Title';
import NotificationList from '../../../component/notificationList/notificationList';
import { WebsocketContext } from '../../../config/reducer/Websocket';
import { APIGetAlerts } from '../../../config/restAPI/FetchAPI';
import { APIPatchAlerts } from '../../../config/restAPI/PatchAPI';
import { NotificationContext } from '../../../context/NotificationContext';
import { APIWSConnect } from '../../../config/restAPI/PostAPI';
import { APIWSDisconnect } from '../../../config/restAPI/DeleteAPI';
import { AuthContext } from '../../../config/reducer/Auth';
import PrevIcon from './img/prevIcon.svg';
import NextIcon from './img/nextIcon.svg';
import PrevIconDisabled from './img/prevIcon-disabled.svg';
import NextIconDisabled from './img/nextIcon-disabled.svg';
import NotificationIcon from './img/notification-icon.svg';

const loadTheme = createTheme({
    palette: {
        amcolor: {
            main: '#365A6D',
        },
    },
});

const NotificationHistory = () => {
    const payloadContext = useContext(WebsocketContext);
    const [state, setState] = useState();
    const [navigtionClick, setNavigtionClick, navigationState, setNavigationState, idleState, setIdleState] = useOutletContext();
    const [notifFilter, setNotifFilter] = useState('all');
    const [notification, setNotification] = useState();
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPerPage] = useState(15);
    const [totalPages, setTotalPages] = useState(0);
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const userID = Cookies.get('_id');

    const location = useLocation();
    const allData = location?.state;
    const privileges = location?.state?.user?.privileges;
    const navigate = useNavigate();
    const context = useContext(AuthContext);
    const { reConnect } = context;

    const {
        setChannel, data: websocketData, newPayloadId, selectAsset, closeAssetCard, selectedAsset, CloseConnection,
    } = payloadContext;

    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

    const {
        data,
        setData,
        unreadCount,
    } = useContext(NotificationContext);

    // Using local storage to save user's alert type filter preferences
    const [checkboxStates, setCheckboxStates] = useState(() => {
        const savedPreferences = JSON.parse(localStorage.getItem('alertPreferences'));
        return savedPreferences || {
            geofence: true,
            unplugged: true,
            assetStatus: true,
            overspeeding: true,
        };
    });

    // Proper handling of checkbox states for alert type filter and setting them in local storage
    const handleToggle = (key) => {
        setCheckboxStates((prevState) => {
            const newState = { ...prevState, [key]: !prevState[key] };
            localStorage.setItem('alertPreferences', JSON.stringify(newState));
            return newState;
        });
    };

    const filteredData = data?.filter((item) => {
        if (checkboxStates.geofence && item.type === 101) return true;
        if (checkboxStates.unplugged && item.type === 102) return true;
        if (checkboxStates.assetStatus && item.type === 103) return true;
        if (checkboxStates.overspeeding && item.type === 104) return true;
        return false;
    }) || [];

    const handleChecked = (id) => {
        setSelectedCheckboxes((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const notificationNavigationList = [
        {
            text: 'Notifications History', value: 1, path: '/notifications/history', enabled: true,
        },
        {
            text: 'Alert Configurations', value: 2, path: '/notifications/configuration', enabled: true,
        },
        {
            text: 'Export Alerts', value: 3, path: '/notifications/exportalerts', enabled: true,
        },
    ];

    const CustomLoader = () => (
        <div style={{ padding: '24px', margin: 'auto', height: 'auto' }}>
            <ThemeProvider theme={loadTheme}>
                <CircularProgress color="amcolor" variant="indeterminate" />
            </ThemeProvider>
        </div>
    );

    const navigationList = [
        {
            text: 'All',
            value: 'all',
            enabled: true,
        },
        {
            text: 'Unread',
            value: 'unread',
            enabled: true,
        },
        {
            text: 'Archived',
            value: 'archived',
            enabled: false,
        },
    ];

    const fetchAlerts = async (params) => {
        setLoading(true);
        try {
            const filterParams = { ...params };
            if (notifFilter === 'unread') {
                filterParams.isUnread = true;
            }
            const result = await APIGetAlerts(setLoading, token, agencyID, filterParams);
            const notifications = result?.data?.data || [];
            const totalData = result?.data?.totalData || 0;
            setData(notifications);
            setLoading(false);
            if (totalData === 0) {
                setTotalPages(1);
            } else {
                setTotalPages(Math.ceil(totalData / totalPerPage));
            }
        } catch (err) {
            setLoading(false);
            console.error('Error fetching alerts:', err);
            setData([]);
            setTotalPages(0);
        }
    };

    const fetchAllNotificationOids = async () => {
        const params = {
            isUnread: true,
        };
        const result = await APIGetAlerts(setLoading, token, agencyID, params);
        const oids = result?.data?.data?.map((alert) => alert.oid) || [];
        return oids;
    };

    const markAlertAsRead = async () => {
        try {
            if (Object.values(selectedCheckboxes).every((v) => v === false)) {
                const allNotificationsOids = await fetchAllNotificationOids();
                if (allNotificationsOids.length === 0) {
                    setLoading(false);
                    fetchAlerts({
                        pageNumber: pageNumber,
                        totalPerPage: totalPerPage,
                    });
                }
                const params = {
                    input: {
                        oids: allNotificationsOids,
                        read: true,
                    },
                };
                const result = await APIPatchAlerts(token, setLoading, agencyID, params);
                if (result?.data?.status === 200) {
                    setLoading(false);
                    fetchAlerts({
                        pageNumber: pageNumber,
                        totalPerPage: totalPerPage,
                    });
                }
                setNotifFilter('all');
            } else {
                const keys = Object.keys(selectedCheckboxes).filter((k) => selectedCheckboxes[k]);
                const params = {
                    input: {
                        oids: keys,
                        read: true,
                    },
                };
                const result = await APIPatchAlerts(token, setLoading, agencyID, params);
                if (result?.data?.status === 200) {
                    setLoading(false);
                    fetchAlerts({
                        pageNumber: pageNumber,
                        totalPerPage: totalPerPage,
                    });
                }
                setSelectedCheckboxes({});
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    useEffect(() => {
        setPageNumber(1);
    }, [notifFilter, checkboxStates]);

    useEffect(() => {
        fetchAlerts({
            pageNumber: pageNumber,
            totalPerPage: totalPerPage,
        });
    }, [pageNumber, notifFilter, checkboxStates]);

    const urlParams = new URLSearchParams(location.search);
    const agencyFromURL = urlParams.get('agency');

    useEffect(() => {
        const switchAgency = async () => {
            if (agencyFromURL) {
                const storedAgencies = JSON.parse(localStorage.getItem('listAgency') || '[]');
                const matchedAgency = storedAgencies.find((agency) => agency.agency.agencyId === agencyFromURL);
                if (matchedAgency) {
                    const selectedAgency = {
                        id: matchedAgency.role === 'System Administrator' ? 'admin' : matchedAgency.agency.agencyId,
                        name: matchedAgency.agency.agencyName || 'Admin Console',
                        agencyUUID: matchedAgency.agency.oid,
                        chatBotEnabled: matchedAgency.chatBotEnabled,
                        isSuspended: matchedAgency.agency.isSuspended,
                        role: matchedAgency.role,
                        tgNotification: matchedAgency.tgNotification,
                    };
                    try {
                        const websocketDisconnected = await APIWSDisconnect(token, agencyID);
                        if (websocketDisconnected.status === 200) {
                            CloseConnection('disconnect');
                            const postResponse = await APIWSConnect(token, selectedAgency.agencyUUID);
                            if (postResponse.status === 200) {
                                reConnect(selectedAgency, null);
                                setChannel(selectedAgency.id);
                                navigate('/notifications/history', { replace: true });
                                window.location.reload();
                            } else {
                                console.log('Error after sending WebSocket login response');
                            }
                        }
                    } catch (err) {
                        console.log('Error connecting to WebSocket:', err);
                    }
                } else {
                    console.log('Agency not found in localStorage');
                }
            }
        };
        switchAgency();
    }, [agencyFromURL]);

    const getPaginatedData = () => {
        const start = (pageNumber - 1) * totalPerPage;
        const end = pageNumber * totalPerPage;
        if (filteredData.length === 0) {
            return [];
        }
        return filteredData.slice(start, end);
    };

    useEffect(() => {
        if (filteredData.length === 0) {
            setTotalPages(1);
        } else {
            setTotalPages(Math.ceil(filteredData.length / totalPerPage));
        }
    }, [filteredData, totalPerPage]);

    const handleNextPage = () => {
        if (pageNumber < totalPages) {
            setPageNumber((prev) => prev + 1);
        }
    };

    const handlePreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber((prev) => prev - 1);
        }
    };

    return (
        <div className="notification-history-container">
            <ListNavigationTab list={notificationNavigationList} navigtionClick={navigtionClick} setNavigtionClick={setNavigtionClick} />
            <div className="notification-history-header">
                <Title title="Notifications History" subtitle="View all available notifications from the past 7 days" titleSize="1.875rem" subtitleSize="1rem" lineHeight="2.375rem" subtitleLineHeight="1.5rem" />
                <div className="asset-performance-tabs">
                    <NotifConfigTab
                      color="#FFFF"
                      types={navigationList}
                      module="notifications"
                      notifFilter={notifFilter}
                      setNotifFilter={setNotifFilter}
                      notification={notification}
                      setNotification={setNotification}
                      setData={setData}
                      data={data}
                      fetchAlerts={fetchAlerts}
                      markAsRead={markAlertAsRead}
                      selectedCheckboxes={selectedCheckboxes}
                      unread={unreadCount}
                      checkboxStates={checkboxStates}
                      handleToggle={handleToggle}
                    />
                </div>
            </div>
            <div className="notification-history-body" style={{ margin: '-1.5rem 0', position: 'relative', minHeight: '200px' }}>
                {loading ? (
                    <CustomLoader />
                ) : filteredData.length === 0 ? (
                    <div className="no-notifications-message">
                        <img src={NotificationIcon} alt="Empty state" />
                        <p>No notifications available</p>
                    </div>
                ) : (
                    <NotificationList
                      data={getPaginatedData()}
                      selectedCheckboxes={selectedCheckboxes}
                      setSelectedCheckboxes={setSelectedCheckboxes}
                      handleChecked={handleChecked}
                    />
                )}
            </div>
            <div className="notification-pagination-container">
                <button type="button" onClick={handlePreviousPage} disabled={pageNumber === 1}>
                    <img
                      className="notification-pagination-icon"
                      src={pageNumber === 1 ? PrevIconDisabled : PrevIcon}
                      alt="previous"
                      loading="lazy"
                    />
                </button>
                <span>
                    Page {pageNumber} of {totalPages}
                </span>
                <button type="button" onClick={handleNextPage} disabled={pageNumber === totalPages}>
                    <img
                      className="notification-pagination-icon"
                      src={pageNumber === totalPages ? NextIconDisabled : NextIcon}
                      alt="next"
                      loading="lazy"
                    />
                </button>
            </div>
        </div>
    );
};

export default NotificationHistory;
