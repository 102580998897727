/* eslint-disable no-lonely-if, no-unused-expressions, no-shadow */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import { isEqual } from 'lodash';
import { PrimaryButton, SecondaryButton } from '../buttonComponent/ButtonComponent';
import Text from '../text/Text';
import Checkbox from '../checkbox/Checkbox';
import InputTags from '../inputTags/InputTags';
import InputText from '../inputText/InputText';
import GeofenceActionUser from '../actionUser/GeofenceActionUser';
import TextArea from '../textAreaLimit/TextArea';
import TimeSelector from '../timeSelector/timeSelector';
import DropDownList from '../iconDropDown/iconDropDown';
import GreaterThanIcon from './img/greater-than-icon.svg';
import LessThanIcon from './img/less-than-icon.svg';
import RenotifyIcon from './img/renotify-icon.svg';
import { APIGetV3TeamMembers } from '../../config/restAPI/FetchAPI';
import { APICreateAlertPolicy } from '../../config/restAPI/PostAPI';
import { APIPatchAlertPolicy } from '../../config/restAPI/PatchAPI';
import './alerts.css';

const motionStateOptions = [
    {
        value: 'In Motion',
        label: 'In Motion',
        icon:
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6" y="6" width="8" height="8" rx="4" fill="#12B76A" />
            </svg>,
    },
    {
        value: 'Idle',
        label: 'Idle',
        icon:
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6" y="6" width="8" height="8" rx="4" fill="#F79009" />
            </svg>,
    },
    {
        value: 'Stopped',
        label: 'Stopped',
        icon:
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6" y="6" width="8" height="8" rx="4" fill="#F04438" />
            </svg>,
    },
    // {
    //     value: 'Tempered',
    //     label: 'Tempered',
    //     icon:
    //         <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    //             <rect x="6" y="6" width="8" height="8" rx="4" fill="#12B76A" />
    //         </svg>,
    // },
    {
        value: 'In Tow',
        label: 'In Tow',
        icon:
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6" y="6" width="8" height="8" rx="4" fill="#2E90FA" />
            </svg>,
    },
    {
        value: 'Offline',
        label: 'Offline',
        icon:
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="6" y="6" width="8" height="8" rx="4" fill="#667085" />
            </svg>,
    },
    {
        value: 'Crashed',
        label: 'Crashed',
        icon:
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10 6.66666V9.99999M10 13.3333H10.0083M2.5 6.61786V13.3821C2.5 13.6677 2.5 13.8104 2.54207 13.9378C2.57929 14.0504 2.64013 14.1538 2.72053 14.241C2.8114 14.3397 2.93621 14.409 3.18581 14.5477L9.35248 17.9736C9.58881 18.1049 9.70698 18.1705 9.83212 18.1963C9.94288 18.219 10.0571 18.219 10.1679 18.1963C10.293 18.1705 10.4112 18.1049 10.6475 17.9736L16.8142 14.5477C17.0638 14.409 17.1886 14.3397 17.2795 14.241C17.3599 14.1538 17.4207 14.0504 17.4579 13.9378C17.5 13.8104 17.5 13.6677 17.5 13.3821V6.61786C17.5 6.33232 17.5 6.18956 17.4579 6.06222C17.4207 5.94958 17.3599 5.84617 17.2795 5.75893C17.1886 5.66032 17.0638 5.59098 16.8142 5.45232L10.6475 2.02639C10.4112 1.89509 10.293 1.82944 10.1679 1.80371C10.0571 1.78093 9.94288 1.78093 9.83212 1.80371C9.70698 1.82944 9.58881 1.89509 9.35248 2.02639L3.18581 5.45232C2.93621 5.59099 2.8114 5.66032 2.72053 5.75893C2.64013 5.84617 2.57929 5.94958 2.54207 6.06222C2.5 6.18956 2.5 6.33232 2.5 6.61786Z" stroke="#B42318" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>,
    },
];

export default function AssetStatusAlert(props) {
    const { detailsRow, selectionList } = props;
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const listAgency = JSON.parse(localStorage.getItem('listAgency') || '[]');
    const currentAgencyTimezone = listAgency.find((a) => a.agency.oid === agencyID)?.agency?.agencyTz || 'Asia/Kuala_Lumpur';
    const [teamList, setTeamList] = useState([]);
    const [userRemove, setUserRemove] = useState([]);
    const [userAdd, setUserAdd] = useState([]);
    const [triggerTime, setTriggerTime] = useState(detailsRow?.triggerTime || []);
    const [disabledAddUser, setDisabledAddUser] = useState(false);
    const [selectionUser, setSelectionUser] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    const navigate = useNavigate();
    const [editingIndex, setEditingIndex] = useState(null);
    const [disableMainButton, setDisableMainButton] = useState(false);
    const [addAlertValue, setAddAlertValue] = useState({
        alertName: detailsRow?.alertName || '',
        assetStatus: {
            motionState: detailsRow?.assetStatus?.motionState || '',
            dependentActions: {
                statusChange: {
                    enabled: detailsRow?.assetStatus?.dependentActions?.statusChange?.enabled || false,
                },
                greaterThan: {
                    enabled: detailsRow?.assetStatus?.dependentActions?.greaterThan?.enabled || false,
                    timeThreshold: detailsRow?.assetStatus?.dependentActions?.greaterThan?.timeThreshold || '00:00',
                    reNotification: {
                        enabled: detailsRow?.assetStatus?.dependentActions?.greaterThan?.reNotification?.enabled || false,
                        interval: detailsRow?.assetStatus?.dependentActions?.greaterThan?.reNotification?.interval || '00:00',
                        count: detailsRow?.assetStatus?.dependentActions?.greaterThan?.reNotification?.count || 0,
                    },
                },
                lessThan: {
                    enabled: detailsRow?.assetStatus?.dependentActions?.lessThan?.enabled || false,
                    timeThreshold: detailsRow?.assetStatus?.dependentActions?.lessThan?.timeThreshold || '00:00',
                    reNotification: {
                        enabled: detailsRow?.assetStatus?.dependentActions?.lessThan?.reNotification?.enabled || false,
                        interval: detailsRow?.assetStatus?.dependentActions?.lessThan?.reNotification?.interval || '00:00',
                        count: detailsRow?.assetStatus?.dependentActions?.lessThan?.reNotification?.count || 0,
                    },
                },
            },
            independentActions: {
                firstStatusChangeOfDay: {
                    enabled: detailsRow?.assetStatus?.independentActions?.firstStatusChangeOfDay?.enabled || false,
                },
            },
        },
    });
    const [descriptionValue, setDescriptionValue] = useState(detailsRow?.description || '');
    const [listUser, setListUser] = useState(detailsRow?.notifiedUsers || []);
    const [tags, setTags] = useState(detailsRow?.associatedTags || []);
    const [removeTags, setRemoveTags] = useState([]);
    const [tagsHistory, setTagsHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const pageTotal = { totalPerPage: 100 };
    const [selectedMotionStateType, setSelectedMotionStateType] = useState(
        motionStateOptions.find((option) => option.value === detailsRow?.assetStatus?.motionState) || null,
    );

    const formatTimeInput = (input) => {
        const value = input.replace(/[^\d]/g, '');
        const hh = value.slice(0, 2);
        let mm = value.slice(2, 4);
        if (mm.length === 2 && parseInt(mm, 10) > 59) {
            mm = '59';
        }
        let formattedValue = hh;
        if (hh.length === 2) formattedValue += ':';
        formattedValue += mm;
        return formattedValue;
    };

    const inputOnChangeAlertName = (e) => {
        setAddAlertValue({ ...addAlertValue, alertName: e.target.value });
    };

    const handleStatusOccurrenceChange = (e) => {
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                dependentActions: {
                    ...addAlertValue.assetStatus.dependentActions,
                    statusChange: {
                        ...addAlertValue.assetStatus.dependentActions.statusChange,
                        enabled: e.target.checked,
                    },
                },
            },
        }));
    };

    const handleStatusGreaterThanChange = (e) => {
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                dependentActions: {
                    ...addAlertValue.assetStatus.dependentActions,
                    greaterThan: {
                        ...addAlertValue.assetStatus.dependentActions.greaterThan,
                        enabled: e.target.checked,
                    },
                },
            },
        }));
    };

    const handleGreaterThanTimeThresholdChange = (e) => {
        const formattedValue = formatTimeInput(e.target.value);
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                dependentActions: {
                    ...addAlertValue.assetStatus.dependentActions,
                    greaterThan: {
                        ...addAlertValue.assetStatus.dependentActions.greaterThan,
                        timeThreshold: formattedValue,
                    },
                },
            },
        }));
    };

    const handleRenotifyGreaterThanChange = (e) => {
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                dependentActions: {
                    ...addAlertValue.assetStatus.dependentActions,
                    greaterThan: {
                        ...addAlertValue.assetStatus.dependentActions.greaterThan,
                        reNotification: {
                            ...addAlertValue.assetStatus.dependentActions.greaterThan.reNotification,
                            enabled: e.target.checked,
                        },
                    },
                },
            },
        }));
    };

    const handleRenotifyIntervalGreaterThanChange = (e) => {
        const formattedValue = formatTimeInput(e.target.value);
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                dependentActions: {
                    ...addAlertValue.assetStatus.dependentActions,
                    greaterThan: {
                        ...addAlertValue.assetStatus.dependentActions.greaterThan,
                        reNotification: {
                            ...addAlertValue.assetStatus.dependentActions.greaterThan.reNotification,
                            interval: formattedValue,
                        },
                    },
                },
            },
        }));
    };

    const handleRenotifyCountGreaterThanChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                dependentActions: {
                    ...addAlertValue.assetStatus.dependentActions,
                    greaterThan: {
                        ...addAlertValue.assetStatus.dependentActions.greaterThan,
                        reNotification: {
                            ...addAlertValue.assetStatus.dependentActions.greaterThan.reNotification,
                            count: value,
                        },
                    },
                },
            },
        }));
    };

    const handleStatusLessThanChange = (e) => {
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                dependentActions: {
                    ...addAlertValue.assetStatus.dependentActions,
                    lessThan: {
                        ...addAlertValue.assetStatus.dependentActions.lessThan,
                        enabled: e.target.checked,
                    },
                },
            },
        }));
    };

    const handleLessThanTimeThresholdChange = (e) => {
        const formattedValue = formatTimeInput(e.target.value);
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                dependentActions: {
                    ...addAlertValue.assetStatus.dependentActions,
                    lessThan: {
                        ...addAlertValue.assetStatus.dependentActions.lessThan,
                        timeThreshold: formattedValue,
                    },
                },
            },
        }));
    };

    const handleRenotifyLessThanChange = (e) => {
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                dependentActions: {
                    ...addAlertValue.assetStatus.dependentActions,
                    lessThan: {
                        ...addAlertValue.assetStatus.dependentActions.lessThan,
                        reNotification: {
                            ...addAlertValue.assetStatus.dependentActions.lessThan.reNotification,
                            enabled: e.target.checked,
                        },
                    },
                },
            },
        }));
    };

    const handleRenotifyIntervalLessThanChange = (e) => {
        const formattedValue = formatTimeInput(e.target.value);
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                dependentActions: {
                    ...addAlertValue.assetStatus.dependentActions,
                    lessThan: {
                        ...addAlertValue.assetStatus.dependentActions.lessThan,
                        reNotification: {
                            ...addAlertValue.assetStatus.dependentActions.lessThan.reNotification,
                            interval: formattedValue,
                        },
                    },
                },
            },
        }));
    };

    const handleRenotifyCountLessThanChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                dependentActions: {
                    ...addAlertValue.assetStatus.dependentActions,
                    lessThan: {
                        ...addAlertValue.assetStatus.dependentActions.lessThan,
                        reNotification: {
                            ...addAlertValue.assetStatus.dependentActions.lessThan.reNotification,
                            count: value,
                        },
                    },
                },
            },
        }));
    };

    const handleFirstAssetChangeOfDayChange = (e) => {
        setAddAlertValue((addAlertValue) => ({
            ...addAlertValue,
            assetStatus: {
                ...addAlertValue.assetStatus,
                independentActions: {
                    ...addAlertValue.assetStatus.independentActions,
                    firstStatusChangeOfDay: {
                        ...addAlertValue.assetStatus.independentActions.firstStatusChangeOfDay,
                        enabled: e.target.checked,
                    },
                },
            },
        }));
    };

    const backButton = () => {
        navigate('/notifications/configuration', { replace: true });
        setListUser([]);
    };

    const fetchTeam = async (value) => {
        try {
            const result = await APIGetV3TeamMembers(setLoading, token, agencyID, value);
            if (result.data.status === 200) {
                const { data } = result.data;
                if (data.length > 0) {
                    const reducer = data.map((x) => ({
                        id: x.userFirstName || x.userLastName ? `${x.userFirstName} ${x.userLastName}` : '',
                        name: x.userEmail,
                        url: x?.profileImageUrl ? x.profileImageUrl : null,
                        channel: x?.channels?.length > 0 ? x.channels : [],
                        oid: x.oid,
                    }));
                    if (listUser.length > 0) {
                        const ids = new Set(listUser.map((r) => r.oid));
                        const updatedUser = reducer.filter((u) => !ids.has(u.oid));
                        setTeamList(updatedUser);
                    } else {
                        setTeamList(reducer);
                    }
                } else {
                    console.log('No data');
                }
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const createAlert = async (value) => {
        try {
            setDisableMainButton(true);
            const result = await APICreateAlertPolicy(token, setLoading, agencyID, value);
            if (result?.data?.status === 200) {
                setLoading(false);
                setDisableMainButton(false);
                navigate('/notifications/configuration', { state: { snackbarStatus: 'success', snackbarTitle: 'Success', snackbarSub: 'New Asset Status Alert Created!' } });
            } else {
                setLoading(false);
                setDisableMainButton(false);
                navigate('/notifications/configuration', { state: { snackbarStatus: 'error', snackbarTitle: 'Error', snackbarSub: `${result.response.data.status}: ${result.response.data.error.message}` } });
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            setDisableMainButton(false);
        }
    };

    const updateAlert = async (value) => {
        try {
            setDisableMainButton(true);
            const result = await APIPatchAlertPolicy(token, setLoading, agencyID, value);
            if (result.data.status === 200) {
                setLoading(false);
                setDisableMainButton(false);
                navigate('/notifications/configuration', { state: { snackbarStatus: 'success', snackbarTitle: 'Success', snackbarSub: 'Asset Status Alert Updated!' } });
            } else {
                setDisableMainButton(false);
                setLoading(false);
                navigate('/notifications/configuration', { state: { snackbarStatus: 'error', snackbarTitle: 'Error', snackbarSub: `${result.error.code}:${result.error.message}` } });
            }
        } catch (err) {
            setDisableMainButton(false);
            console.log('Update Error');
            setLoading(false);
        }
    };

    useEffect(() => {
        if (listUser.length > 0) {
            fetchTeam(pageTotal);
        }
    }, [listUser]);

    useEffect(() => {
        if (token) {
            fetchTeam(pageTotal);
        }
    }, []);

    const saveUserButton = (selectionValue) => {
        if (detailsRow) {
            if (userAdd.length > 0) {
                const filterAdd = userAdd.filter((x) => x.oid === selectionValue.oid);
                if (filterAdd.length > 0) {
                    setUserAdd(filterAdd);
                } else {
                    setUserAdd([...userAdd, selectionValue]);
                }
            } else {
                setUserAdd([...userAdd, selectionValue]);
            }
        }
        const userWithAlertOn = {
            ...selectionValue,
            alertOn: selectionValue.alertOn || [],
        };
        setListUser([...listUser, userWithAlertOn]);
        setSelectionUser({});
        setDisabledAddUser(false);
    };

    const saveUpdateUserButton = (selectionValue, index) => {
        if (!selectionValue) return;
        if (detailsRow) {
            const existingUserIndex = userAdd.findIndex((x) => x.oid === selectionValue.oid);
            if (existingUserIndex !== -1) {
                const updatedUserAdd = [...userAdd];
                updatedUserAdd[existingUserIndex] = selectionValue;
                setUserAdd(updatedUserAdd);
            } else {
                setUserAdd([...userAdd, selectionValue]);
            }
        }
        const updatedListUser = [...listUser];
        if (index !== -1) {
            updatedListUser[index] = {
                ...updatedListUser[index],
                ...selectionValue,
                alertOn: selectionValue.alertOn || [],
            };
            setListUser(updatedListUser);
        }
        setSelectionUser({});
        setDisabledAddUser(false);
    };

    const addNewUser = () => {
        const user = {
            id: '',
            url: '',
            name: '',
            channels: [],
        };
        setSelectionUser(user);
        setDisabledAddUser(true);
    };

    const removeUser = (index, tagsValue) => {
        if (detailsRow) {
            if (userRemove.length > 0) {
                const filterRemove = userRemove.filter((x) => x.oid === tagsValue.oid);
                if (filterRemove.length > 0) {
                    setUserRemove(filterRemove);
                } else {
                    setUserRemove([...userRemove, tagsValue]);
                }
            } else {
                setUserRemove([...userRemove, tagsValue]);
            }
        }
        const removeArray = [...listUser];
        if (index !== -1) {
            removeArray.splice(index, 1);
            setListUser(removeArray);
        }
    };

    const editUser = (index, updatedUser) => {
        if (updatedUser) {
            const updatedListUser = [...listUser];
            if (index !== -1) {
                updatedListUser[index] = {
                    ...updatedListUser[index],
                    ...updatedUser,
                    alertOn: updatedUser.channel || [],
                };
                setListUser(updatedListUser);
            }
            setSelectionUser({});
            setDisableMainButton(false);
        }
    };

    const userCancel = () => {
        setEditingIndex(null);
        setSelectionUser({});
        setDisabledAddUser(false);
    };

    const checkingReducer = () => {
        const value = { alertPolicy: {} };

        value.alertPolicy.oid = detailsRow.oid;

        if (addAlertValue.alertName !== detailsRow.alertName) {
            value.alertPolicy.name = addAlertValue.alertName;
        }

        if (descriptionValue !== detailsRow.description) {
            value.alertPolicy.description = descriptionValue;
        }

        if (userRemove.length > 0) {
            value.alertPolicy.removeNotifiedUsers = userRemove.map((x) => x.oid);
        }

        if (userAdd.length > 0) {
            value.alertPolicy.addNotifiedUsers = userAdd.map((x) => ({
                userOid: x.oid,
                alertOn: x.alertOn || [],
            }));
        }

        if (tags.length > 0) {
            value.alertPolicy.addAssociatedTags = tags;
        }

        if (removeTags.length > 0) {
            value.alertPolicy.removeAssociatedTags = removeTags;
        }

        value.alertPolicy.triggerTime = !isEqual(triggerTime, detailsRow.triggerTime) ? triggerTime : detailsRow.triggerTime;

        value.alertPolicy.assetStatus = {
            motionState: selectedMotionStateType?.value,
            dependentActions: {
                statusChange: {
                    enabled: addAlertValue.assetStatus.dependentActions.statusChange.enabled,
                },
                greaterThan: {
                    enabled: addAlertValue.assetStatus.dependentActions.greaterThan.enabled,
                    timeThreshold: addAlertValue.assetStatus.dependentActions.greaterThan.timeThreshold,
                    reNotification: {
                        enabled: addAlertValue.assetStatus.dependentActions.greaterThan.reNotification.enabled,
                        interval: addAlertValue.assetStatus.dependentActions.greaterThan.reNotification.interval,
                        count: addAlertValue.assetStatus.dependentActions.greaterThan.reNotification.count,
                    },
                },
                lessThan: {
                    enabled: addAlertValue.assetStatus.dependentActions.lessThan.enabled,
                    timeThreshold: addAlertValue.assetStatus.dependentActions.lessThan.timeThreshold,
                    reNotification: {
                        enabled: addAlertValue.assetStatus.dependentActions.lessThan.reNotification.enabled,
                        interval: addAlertValue.assetStatus.dependentActions.lessThan.reNotification.interval,
                        count: addAlertValue.assetStatus.dependentActions.lessThan.reNotification.count,
                    },
                },
            },
            independentActions: {
                firstStatusChangeOfDay: {
                    enabled: addAlertValue.assetStatus.independentActions.firstStatusChangeOfDay.enabled,
                },
            },
        };

        return value;
    };

    const checkingButtonDisabled = () => {
        const value = {};

        if (addAlertValue.alertName !== detailsRow.alertName) {
            value.alertName = addAlertValue.alertName;
        }

        if (descriptionValue !== detailsRow.description) {
            value.description = descriptionValue;
        }

        if (userRemove.length > 0) {
            value.removeUsers = userRemove.map((x) => x.oid);
        }

        if (userAdd.length > 0) {
            value.addUsers = userAdd.map((x) => ({
                userOid: x.oid,
                alertOn: x.alertOn || [],
            }));
        }

        if (!isEqual(tags, detailsRow.associatedTags)) {
            value.addAssociatedTags = tags;
        }

        if (removeTags.length > 0) {
            value.removeAssociatedTags = removeTags;
        }

        if (!isEqual(triggerTime, detailsRow.triggerTime)) {
            value.triggerTime = triggerTime;
        }

        if (!triggerTime.every(({ startTime, endTime }) => {
            const toMinutes = (time) => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };
            return toMinutes(endTime) >= toMinutes(startTime);
        })) return false;

        // Add assetStatus check
        const newAssetStatus = {
            motionState: selectedMotionStateType?.value,
            dependentActions: {
                statusChange: {
                    enabled: addAlertValue?.assetStatus?.dependentActions?.statusChange?.enabled,
                },
                greaterThan: {
                    enabled: addAlertValue?.assetStatus?.dependentActions?.greaterThan?.enabled,
                    timeThreshold: addAlertValue?.assetStatus?.dependentActions?.greaterThan?.timeThreshold,
                    reNotification: {
                        enabled: addAlertValue?.assetStatus?.dependentActions?.greaterThan?.reNotification?.enabled,
                        interval: addAlertValue?.assetStatus?.dependentActions?.greaterThan?.reNotification?.interval,
                        count: addAlertValue?.assetStatus?.dependentActions?.greaterThan?.reNotification?.count,
                    },
                },
                lessThan: {
                    enabled: addAlertValue?.assetStatus?.dependentActions?.lessThan?.enabled,
                    timeThreshold: addAlertValue?.assetStatus?.dependentActions?.lessThan?.timeThreshold,
                    reNotification: {
                        enabled: addAlertValue?.assetStatus?.dependentActions?.lessThan?.reNotification?.enabled,
                        interval: addAlertValue?.assetStatus?.dependentActions?.lessThan?.reNotification?.interval,
                        count: addAlertValue?.assetStatus?.dependentActions?.lessThan?.reNotification?.count,
                    },
                },
            },
            independentActions: {
                firstStatusChangeOfDay: {
                    enabled: addAlertValue?.assetStatus?.independentActions?.firstStatusChangeOfDay?.enabled,
                },
            },
        };

        if (!isEqual(newAssetStatus, detailsRow.assetStatus)) {
            value.assetStatus = newAssetStatus;
        }

        return Object.keys(value).length > 0;
    };

    useEffect(() => {
        const isValidTriggerTime = triggerTime?.every(({ startTime, endTime }) => {
            const toMinutes = (time) => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };
            return toMinutes(endTime) >= toMinutes(startTime);
        });
        if (detailsRow) {
            if (checkingButtonDisabled()) {
                setDisableMainButton(true);
            } else {
                setDisableMainButton(false);
            }
        } else {
            if (addAlertValue.alertName) {
                if (triggerTime) {
                    isValidTriggerTime ? setDisableMainButton(true) : setDisableMainButton(false);
                } else {
                    setDisableMainButton(true);
                }
            } else {
                setDisableMainButton(false);
            }
        }
    }, [addAlertValue, tags, listUser, descriptionValue, triggerTime, detailsRow, selectedMotionStateType]);

    const finalCreateButton = () => {
        try {
            if (detailsRow) {
                const updateList = checkingReducer();
                updateAlert(updateList);
            } else {
                const reducer = {
                    alertPolicy: {
                        name: addAlertValue.alertName,
                        isEnabled: true,
                        notifiedUsers: listUser.length > 0
                            ? listUser.map((x) => ({
                                userOid: x.oid,
                                alertOn: x.alertOn || [],
                            })) : [],
                        associatedTags: tags,
                        alertType: selectionList.value,
                        agencyOid: agencyID,
                        description: descriptionValue,
                        timezone: currentAgencyTimezone,
                        triggerTime: triggerTime,
                        assetStatus: {
                            motionState: selectedMotionStateType?.value,
                            dependentActions: {
                                statusChange: {
                                    enabled: addAlertValue?.assetStatus?.dependentActions?.statusChange?.enabled,
                                },
                                greaterThan: {
                                    enabled: addAlertValue?.assetStatus?.dependentActions?.greaterThan?.enabled,
                                    timeThreshold: addAlertValue?.assetStatus?.dependentActions?.greaterThan?.timeThreshold,
                                    reNotification: {
                                        enabled: addAlertValue?.assetStatus?.dependentActions?.greaterThan?.reNotification?.enabled,
                                        interval: addAlertValue?.assetStatus?.dependentActions?.greaterThan?.reNotification?.interval,
                                        count: addAlertValue?.assetStatus?.dependentActions?.greaterThan?.reNotification?.count,
                                    },
                                },
                                lessThan: {
                                    enabled: addAlertValue?.assetStatus?.dependentActions?.lessThan?.enabled,
                                    timeThreshold: addAlertValue?.assetStatus?.dependentActions?.lessThan?.timeThreshold,
                                    reNotification: {
                                        enabled: addAlertValue?.assetStatus?.dependentActions?.lessThan?.reNotification?.enabled,
                                        interval: addAlertValue?.assetStatus?.dependentActions?.lessThan?.reNotification?.interval,
                                        count: addAlertValue?.assetStatus?.dependentActions?.lessThan?.reNotification?.count,
                                    },
                                },
                            },
                            independentActions: {
                                firstStatusChangeOfDay: {
                                    enabled: addAlertValue?.assetStatus?.independentActions?.firstStatusChangeOfDay?.enabled,
                                },
                            },
                        },
                    },
                };
                createAlert(reducer);
            }
        } catch (err) {
            console.log('error:', err);
        }
    };

    const formatTimezone = (timezone) => {
        const now = moment.tz(timezone);
        const offset = now.format('Z');
        return `${timezone.replace('_', ' ')} UTC${offset}`;
    };

    return (
    <div className="geofence-alert-details-container">
        <div className="geofence-alert-details-policy-container">
            <Text
              text="Alert Policy Name"
              weight="500"
              size="0.875rem"
              height="1.25rem"
              color="#344054"
            />
            <InputText placeholder="" value={addAlertValue.alertName} onChange={inputOnChangeAlertName} width="508px" />
        </div>
        <div className="geofence-alert-details-description-container">
            <div className="configuration-body-row">
                <div className="configuration-text-container">
                    <div className="configuration-main-text">
                        Description
                    </div>
                    <div className="configuration-supporting-text">
                        Add a brief description to further identify the alert or for specific notes
                    </div>
                </div>
                <TextArea descriptionValue={descriptionValue} setDescriptionValue={setDescriptionValue} width="535px" />
            </div>
        </div>
        <div className="geofence-alert-details-areas-container">
            <div>
                <Text
                  text="Asset Status"
                  weight="500"
                  size="0.875rem"
                  height="1.25rem"
                  color="#344054"
                />
            </div>
            <DropDownList
              placeholder="Select motion state"
              selectedValue={selectedMotionStateType}
              setSelectedValue={setSelectedMotionStateType}
              options={motionStateOptions}
              width="535px"
            />
        </div>
        {/* Dependent Actions */}
        <div className="geofence-alert-details-description-container">
            <div className="configuration-body-row">
                <div className="configuration-text-container">
                    <div className="configuration-main-text">
                        Dependent Actions
                    </div>
                    <div className="configuration-supporting-text">
                        Notification actions that are dependent on the configured Asset Status above
                    </div>
                </div>
            </div>
            <div>
                {/* Notify Upon Asset Status Occurrence */}
                <div className="g-detail-action-checkbox-container" style={{ marginBottom: '16px' }}>
                    {/* Checkbox for Notify Upon Asset Status Occurrence */}
                    <div style={{ position: 'relative', top: '3px' }}>
                        <Checkbox
                          width="16px"
                          height="16px"
                          borderRadius="4px"
                          name="statusChange"
                          checked={addAlertValue.assetStatus.dependentActions.statusChange.enabled}
                          onChange={handleStatusOccurrenceChange}
                        />
                    </div>
                    <div>
                        <Text
                          text="Notify upon asset status occurrence"
                          weight="500"
                          size="0.875rem"
                          height="1.25rem"
                          color="#344054"
                        />
                        <div className="g-detail-action-checkbox-text-header">
                            Triggers the notification when an asset changes to the specified status
                        </div>
                    </div>
                </div>
                {/* Notify when set asset status is greater than specified time */}
                <div className="g-detail-action-checkbox-container" style={{ marginBottom: '16px' }}>
                    {/* Checkbox for Notify when set asset status is greater than specified time */}
                    <div style={{ position: 'relative', top: '3px' }}>
                        <Checkbox
                          width="16px"
                          height="16px"
                          borderRadius="4px"
                          name="greaterThan"
                          checked={addAlertValue.assetStatus.dependentActions.greaterThan.enabled}
                          onChange={(e) => {
                                handleStatusGreaterThanChange(e);
                                if (!e.target.checked) {
                                    handleRenotifyGreaterThanChange({ target: { checked: false } });
                                }
                          }}
                        />
                    </div>
                    <div>
                        <Text
                          text="Notify when set asset status is greater than specified time"
                          weight="500"
                          size="0.875rem"
                          height="1.25rem"
                          color="#344054"
                        />
                        <div className="g-detail-action-checkbox-text-header" style={{ marginTop: '12px' }}>
                            {/* Input for Greater Than Specified Time */}
                            <InputText
                              placeholder="00:00"
                              value={addAlertValue.assetStatus.dependentActions.greaterThan.timeThreshold}
                              onChange={handleGreaterThanTimeThresholdChange}
                              width="7.5rem"
                              icon={GreaterThanIcon}
                              disabled={!addAlertValue.assetStatus.dependentActions.greaterThan.enabled}
                              title="Hour : Minute"
                            />
                            <div className="g-detail-action-checkbox-container" style={{ marginTop: '12px' }}>
                                <div style={{ position: 'relative', top: '3px' }}>
                                    <Checkbox
                                      disabled={!addAlertValue.assetStatus.dependentActions.greaterThan.enabled}
                                      width="16px"
                                      height="16px"
                                      borderRadius="4px"
                                      name="greaterThanReNotification"
                                      checked={addAlertValue.assetStatus.dependentActions.greaterThan.reNotification.enabled}
                                      onChange={handleRenotifyGreaterThanChange}
                                    />
                                </div>
                                <div>
                                    <Text
                                      text="Enable re-notification based on the specified duration and count"
                                      weight="500"
                                      size="0.875rem"
                                      height="1.25rem"
                                      color="#344054"
                                    />
                                    <div className="g-detail-action-checkbox-text-header">
                                        Triggers the notification again when the asset status persists after the first trigger
                                    </div>
                                </div>
                            </div>
                            {/* Re-Notify */}
                            <div
                              style={{
                                marginTop: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                              }}
                            >
                                <div className="g-detail-action-checkbox-text-header" style={{ marginRight: '0.5rem' }}>
                                    Notify again after
                                </div>
                                {/* HH:MM */}
                                <InputText
                                  placeholder="00:00"
                                  value={addAlertValue.assetStatus.dependentActions.greaterThan.reNotification.interval}
                                  onChange={handleRenotifyIntervalGreaterThanChange}
                                  width="7.5rem"
                                  icon={RenotifyIcon}
                                  disabled={!addAlertValue.assetStatus.dependentActions.greaterThan.reNotification.enabled}
                                  title="Hour : Minute"
                                />
                                <div className="g-detail-action-checkbox-text-header" style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}>
                                    for
                                </div>
                                {/* Interval Count */}
                                <InputText
                                  placeholder="0"
                                  value={addAlertValue.assetStatus.dependentActions.greaterThan.reNotification.count}
                                  onChange={handleRenotifyCountGreaterThanChange}
                                  width="1.5rem"
                                  disabled={!addAlertValue.assetStatus.dependentActions.greaterThan.reNotification.enabled}
                                />
                                <div className="g-detail-action-checkbox-text-header" style={{ marginLeft: '0.5rem' }}>
                                    count(s)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Notify when set asset status is less than specified time */}
                <div className="g-detail-action-checkbox-container" style={{ marginBottom: '16px' }}>
                    <div style={{ position: 'relative', top: '3px' }}>
                        <Checkbox
                          width="16px"
                          height="16px"
                          borderRadius="4px"
                          name="lessThan"
                          checked={addAlertValue.assetStatus.dependentActions.lessThan.enabled}
                          onChange={(e) => {
                                handleStatusLessThanChange(e);
                                if (!e.target.checked) {
                                    handleRenotifyLessThanChange({ target: { checked: false } });
                                }
                          }}
                        />
                    </div>
                    <div>
                        <Text
                          text="Notify when set asset status is less than specified time upon a status change"
                          weight="500"
                          size="0.875rem"
                          height="1.25rem"
                          color="#344054"
                        />
                        <div className="g-detail-action-checkbox-text-header" style={{ marginTop: '12px', marginBottom: '-12px' }}>
                                <InputText
                                  placeholder="00:00"
                                  value={addAlertValue.assetStatus.dependentActions.lessThan.timeThreshold}
                                  onChange={handleLessThanTimeThresholdChange}
                                  width="7.5rem"
                                  icon={LessThanIcon}
                                  disabled={!addAlertValue.assetStatus.dependentActions.lessThan.enabled}
                                  title="Hour : Minute"
                                />
                            <div className="g-detail-action-checkbox-container" style={{ marginTop: '12px' }}>
                                <div style={{ position: 'relative', top: '3px' }}>
                                    <Checkbox
                                      disabled={!addAlertValue.assetStatus.dependentActions.lessThan.enabled}
                                      width="16px"
                                      height="16px"
                                      borderRadius="4px"
                                      name="lessThanReNotification"
                                      checked={addAlertValue.assetStatus.dependentActions.lessThan.reNotification.enabled}
                                      onChange={handleRenotifyLessThanChange}
                                    />
                                </div>
                                <div>
                                    <Text
                                      text="Enable re-notification based on the specified duration and count"
                                      weight="500"
                                      size="0.875rem"
                                      height="1.25rem"
                                      color="#344054"
                                    />
                                    <div className="g-detail-action-checkbox-text-header">
                                        Triggers the notification again when the asset status persists after the first trigger
                                    </div>
                                </div>
                            </div>
                            {/* Re-Notify */}
                            <div
                              style={{
                                marginTop: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                                }}
                            >
                                <div className="g-detail-action-checkbox-text-header" style={{ marginRight: '0.5rem' }}>
                                    Notify again after
                                </div>
                                {/* HH:MM */}
                                <InputText
                                  placeholder="00:00"
                                  value={addAlertValue.assetStatus.dependentActions.lessThan.reNotification.interval}
                                  onChange={handleRenotifyIntervalLessThanChange}
                                  width="7.5rem"
                                  icon={RenotifyIcon}
                                  disabled={!addAlertValue.assetStatus.dependentActions.lessThan.reNotification.enabled}
                                  title="Hour : Minute"
                                />
                                <div className="g-detail-action-checkbox-text-header" style={{ marginRight: '0.5rem', marginLeft: '0.5rem' }}>
                                    for
                                </div>
                                {/* Interval Count */}
                                <InputText
                                  placeholder="0"
                                  value={addAlertValue.assetStatus.dependentActions.lessThan.reNotification.count}
                                  onChange={handleRenotifyCountLessThanChange}
                                  width="1.5rem"
                                  disabled={!addAlertValue.assetStatus.dependentActions.lessThan.reNotification.enabled}
                                />
                                <div className="g-detail-action-checkbox-text-header" style={{ marginLeft: '0.5rem' }}>
                                    count(s)
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Independent Actions */}
        <div className="geofence-alert-details-description-container">
            <div className="configuration-body-row" style={{ marginBottom: '13px' }}>
                <div className="configuration-text-container">
                    <div className="configuration-main-text">
                        Independent Actions
                    </div>
                    <div className="configuration-supporting-text">
                        Notification actions that are independent of the configured Asset Status and will always trigger
                    </div>
                </div>
            </div>
            <div>
                <div className="g-detail-action-checkbox-container" style={{ marginBottom: '16px' }}>
                    <div style={{ position: 'relative', top: '3px' }}>
                        <Checkbox
                          width="16px"
                          height="16px"
                          borderRadius="4px"
                          name="firstStatusChangeOfDay"
                          checked={addAlertValue.assetStatus.independentActions.firstStatusChangeOfDay.enabled}
                          onChange={handleFirstAssetChangeOfDayChange}
                        />
                    </div>
                    <div>
                        <Text
                          text="Notify upon first asset change of the day"
                          weight="500"
                          size="0.875rem"
                          height="1.25rem"
                          color="#344054"
                        />
                        <div className="g-detail-action-checkbox-text-header">
                            Triggers the notification when an asset changes statuses for the first time in a day
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="geofence-alert-details-areas-container">
            <div>
                <Text
                  text="Asset/Device Tags"
                  weight="500"
                  size="0.875rem"
                  height="1.25rem"
                  color="#344054"
                />
                <Text
                  text="Grant permission to tagged resources. Leave blank to grant permission to all resources"
                  weight="400"
                  size="0.875rem"
                  height="1.25rem"
                  color="#475467"
                />
            </div>
            <InputTags
              tags={tags}
              setTags={setTags}
              removedTags={removeTags}
              setRemovedTags={setRemoveTags}
              tagsHistory={tagsHistory}
            />
        </div>
        <div className="geofence-alert-details-areas-container">
            <div>
                <Text
                  text="Alert Trigger Time"
                  weight="500"
                  size="0.875rem"
                  height="1.25rem"
                  color="#344054"
                />
                <Text
                  text={(
                        <>
                            Leave all days unchecked to trigger the alert at all times.
                            <br />
                            Alerts will be triggered based on the
                            {' '}
                            {formatTimezone(currentAgencyTimezone)}
                            {' '}
                            time zone.
                        </>
                  )}
                  weight="400"
                  size="0.875rem"
                  height="1.25rem"
                  color="#475467"
                />
            </div>
            <div className="time-selector-container" style={{ marginBottom: '-15px' }}>
                <TimeSelector triggerTime={triggerTime} setTriggerTime={setTriggerTime} />
            </div>
        </div>
        <div className="geofence-alert-details-areas-container">
            <div>
                <Text
                  text="Notified Users"
                  weight="500"
                  size="0.875rem"
                  height="1.25rem"
                  color="#344054"
                />
                <Text
                  text="Select users to be notified when selected action(s) have been triggered"
                  weight="400"
                  size="0.875rem"
                  height="1.25rem"
                  color="#475467"
                />
            </div>
            <GeofenceActionUser
              users={listUser}
              listDropdown={teamList}
              newUserFunction={addNewUser}
              userCancel={userCancel}
              disabledAddUser={disabledAddUser}
              alignment="flex-end"
              saveUser={saveUserButton}
              saveUpdateUser={saveUpdateUserButton}
              editUser={editUser}
              removeUser={removeUser}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              loading={loading}
              editingIndex={editingIndex}
              setDisabledAddUser={setDisabledAddUser}
            />
        </div>
        <div className="g-detail-button-container">
            <div className="geofence-alert-details-button">
                <SecondaryButton label="Cancel" onClickFunction={backButton} />
                <PrimaryButton label={detailsRow ? 'Update' : 'Create'} disable={!disableMainButton} loading={loading} onClickFunction={finalCreateButton} />
            </div>
        </div>
    </div>
    );
}
