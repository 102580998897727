/* eslint-disable no-lonely-if, no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import { isEqual } from 'lodash';
import { PrimaryButton, SecondaryButton } from '../buttonComponent/ButtonComponent';
import Text from '../text/Text';
import Checkbox from '../checkbox/Checkbox';
import InputTags from '../inputTags/InputTags';
import InputText from '../inputText/InputText';
import GeofenceActionUser from '../actionUser/GeofenceActionUser';
import TextArea from '../textAreaLimit/TextArea';
import TimeSelector from '../timeSelector/timeSelector';
import { APIGetV3TeamMembers } from '../../config/restAPI/FetchAPI';
import { APICreateAlertPolicy } from '../../config/restAPI/PostAPI';
import { APIPatchAlertPolicy } from '../../config/restAPI/PatchAPI';
import './alerts.css';

export default function OverspeedingAlert(props) {
    const { detailsRow, selectionList } = props;
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');
    const listAgency = JSON.parse(localStorage.getItem('listAgency') || '[]');
    const currentAgencyTimezone = listAgency.find((a) => a.agency.oid === agencyID)?.agency?.agencyTz || 'Asia/Kuala_Lumpur';
    const [teamList, setTeamList] = useState([]);
    const [userRemove, setUserRemove] = useState([]);
    const [userAdd, setUserAdd] = useState([]);
    const [triggerTime, setTriggerTime] = useState(detailsRow?.triggerTime || []);
    const [disabledAddUser, setDisabledAddUser] = useState(false);
    const [selectionUser, setSelectionUser] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    const navigate = useNavigate();
    const [editingIndex, setEditingIndex] = useState(null);
    const [disableMainButton, setDisableMainButton] = useState(false);
    const [addAlertValue, setAddAlertValue] = useState({
        alertName: detailsRow?.alertName || '',
        overspeeding: {
            speedLimit: detailsRow?.overspeeding?.speedLimit || 0,
            actions: {
                speedLimitBreach: {
                    enabled: detailsRow?.overspeeding?.actions?.speedLimitBreach?.enabled || false,
                },
            },
        },
    });
    const [descriptionValue, setDescriptionValue] = useState(detailsRow?.description || '');
    const [listUser, setListUser] = useState(detailsRow?.notifiedUsers || []);
    const [tags, setTags] = useState(detailsRow?.associatedTags || []);
    const [removeTags, setRemoveTags] = useState([]);
    const [tagsHistory, setTagsHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const pageTotal = { totalPerPage: 100 };

    const inputOnChangeAlertName = (e) => {
        setAddAlertValue({
            ...addAlertValue,
            alertName: e.target.value,
        });
    };

    const inputOnChangeSpeedLimit = (e) => {
        setAddAlertValue({
            ...addAlertValue,
            overspeeding: {
                ...addAlertValue.overspeeding,
                speedLimit: e.target.value,
            },
        });
    };

    const handleSpeedLimitBreachChange = (e) => {
        setAddAlertValue({
            ...addAlertValue,
            overspeeding: {
                ...addAlertValue.overspeeding,
                actions: {
                    ...addAlertValue.overspeeding.actions,
                    speedLimitBreach: {
                        ...addAlertValue.overspeeding.actions.speedLimitBreach,
                        enabled: e.target.checked,
                    },
                },
            },
        });
    };

    const backButton = () => {
        navigate('/notifications/configuration', { replace: true });
        setListUser([]);
    };

    const fetchTeam = async (value) => {
        try {
            const result = await APIGetV3TeamMembers(setLoading, token, agencyID, value);
            if (result.data.status === 200) {
                const { data } = result.data;
                if (data.length > 0) {
                    const reducer = data.map((x) => ({
                        id: x.userFirstName || x.userLastName ? `${x.userFirstName} ${x.userLastName}` : '',
                        name: x.userEmail,
                        url: x?.profileImageUrl ? x.profileImageUrl : null,
                        channel: x?.channels?.length > 0 ? x.channels : [],
                        oid: x.oid,
                    }));
                    if (listUser.length > 0) {
                        const ids = new Set(listUser.map((r) => r.oid));
                        const updatedUser = reducer.filter((u) => !ids.has(u.oid));
                        setTeamList(updatedUser);
                    } else {
                        setTeamList(reducer);
                    }
                } else {
                    console.log('No data');
                }
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const createAlert = async (value) => {
        try {
            setDisableMainButton(true);
            const result = await APICreateAlertPolicy(token, setLoading, agencyID, value);
            if (result?.data?.status === 200) {
                setLoading(false);
                setDisableMainButton(false);
                navigate('/notifications/configuration', { state: { snackbarStatus: 'success', snackbarTitle: 'Success', snackbarSub: 'New Overspeeding Alert Created!' } });
            } else {
                setLoading(false);
                setDisableMainButton(false);
                navigate('/notifications/configuration', { state: { snackbarStatus: 'error', snackbarTitle: 'Error', snackbarSub: `${result.response.data.status}: ${result.response.data.error.message}` } });
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            setDisableMainButton(false);
        }
    };

    const updateAlert = async (value) => {
        try {
            setDisableMainButton(true);
            const result = await APIPatchAlertPolicy(token, setLoading, agencyID, value);
            if (result.data.status === 200) {
                setLoading(false);
                setDisableMainButton(false);
                navigate('/notifications/configuration', { state: { snackbarStatus: 'success', snackbarTitle: 'Success', snackbarSub: 'Overspeeding Alert Updated!' } });
            } else {
                setDisableMainButton(false);
                setLoading(false);
                navigate('/notifications/configuration', { state: { snackbarStatus: 'error', snackbarTitle: 'Error', snackbarSub: `${result.error.code}:${result.error.message}` } });
            }
        } catch (err) {
            setDisableMainButton(false);
            console.log('Update Error');
            setLoading(false);
        }
    };

    useEffect(() => {
        if (listUser.length > 0) {
            fetchTeam(pageTotal);
        }
    }, [listUser]);

    useEffect(() => {
        if (token) {
            fetchTeam(pageTotal);
        }
    }, []);

    const saveUserButton = (selectionValue) => {
        if (detailsRow) {
            if (userAdd.length > 0) {
                const filterAdd = userAdd.filter((x) => x.oid === selectionValue.oid);
                if (filterAdd.length > 0) {
                    setUserAdd(filterAdd);
                } else {
                    setUserAdd([...userAdd, selectionValue]);
                }
            } else {
                setUserAdd([...userAdd, selectionValue]);
            }
        }
        const userWithAlertOn = {
            ...selectionValue,
            alertOn: selectionValue.alertOn || [],
        };
        setListUser([...listUser, userWithAlertOn]);
        setSelectionUser({});
        setDisabledAddUser(false);
    };

    const saveUpdateUserButton = (selectionValue, index) => {
        if (!selectionValue) return;
        if (detailsRow) {
            const existingUserIndex = userAdd.findIndex((x) => x.oid === selectionValue.oid);
            if (existingUserIndex !== -1) {
                const updatedUserAdd = [...userAdd];
                updatedUserAdd[existingUserIndex] = selectionValue;
                setUserAdd(updatedUserAdd);
            } else {
                setUserAdd([...userAdd, selectionValue]);
            }
        }
        const updatedListUser = [...listUser];
        if (index !== -1) {
            updatedListUser[index] = {
                ...updatedListUser[index],
                ...selectionValue,
                alertOn: selectionValue.alertOn || [],
            };
            setListUser(updatedListUser);
        }
        setSelectionUser({});
        setDisabledAddUser(false);
    };

    const addNewUser = () => {
        const user = {
            id: '',
            url: '',
            name: '',
            channels: [],
        };
        setSelectionUser(user);
        setDisabledAddUser(true);
    };

    const removeUser = (index, tagsValue) => {
        if (detailsRow) {
            if (userRemove.length > 0) {
                const filterRemove = userRemove.filter((x) => x.oid === tagsValue.oid);
                if (filterRemove.length > 0) {
                    setUserRemove(filterRemove);
                } else {
                    setUserRemove([...userRemove, tagsValue]);
                }
            } else {
                setUserRemove([...userRemove, tagsValue]);
            }
        }
        const removeArray = [...listUser];
        if (index !== -1) {
            removeArray.splice(index, 1);
            setListUser(removeArray);
        }
    };

    const editUser = (index, updatedUser) => {
        if (updatedUser) {
            const updatedListUser = [...listUser];
            if (index !== -1) {
                updatedListUser[index] = {
                    ...updatedListUser[index],
                    ...updatedUser,
                    alertOn: updatedUser.channel || [],
                };
                setListUser(updatedListUser);
            }
            setSelectionUser({});
            setDisableMainButton(false);
        }
    };

    const userCancel = () => {
        setEditingIndex(null);
        setSelectionUser({});
        setDisabledAddUser(false);
    };

    const checkingReducer = () => {
        const value = { alertPolicy: {} };

        value.alertPolicy.oid = detailsRow.oid;

        if (addAlertValue.alertName !== detailsRow.alertName) {
            value.alertPolicy.name = addAlertValue.alertName;
        }

        if (descriptionValue !== detailsRow.description) {
            value.alertPolicy.description = descriptionValue;
        }

        if (userRemove.length > 0) {
            value.alertPolicy.removeNotifiedUsers = userRemove.map((x) => x.oid);
        }

        if (userAdd.length > 0) {
            value.alertPolicy.addNotifiedUsers = userAdd.map((x) => ({
                userOid: x.oid,
                alertOn: x.alertOn || [],
            }));
        }

        if (tags.length > 0) {
            value.alertPolicy.addAssociatedTags = tags;
        }

        if (removeTags.length > 0) {
            value.alertPolicy.removeAssociatedTags = removeTags;
        }

        value.alertPolicy.triggerTime = !isEqual(triggerTime, detailsRow.triggerTime) ? triggerTime : detailsRow.triggerTime;

        value.alertPolicy.overspeeding = {
            speedLimit: addAlertValue?.overspeeding?.speedLimit,
            actions: {
                speedLimitBreach: {
                    enabled: addAlertValue?.overspeeding?.actions?.speedLimitBreach?.enabled,
                },
            },
        };

        return value;
    };

    const checkingButtonDisabled = () => {
        const value = {};

        if (addAlertValue.alertName !== detailsRow.alertName) {
            value.alertName = addAlertValue.alertName;
        }

        if (descriptionValue !== detailsRow.description) {
            value.description = descriptionValue;
        }

        if (userRemove.length > 0) {
            value.removeUsers = userRemove.map((x) => x.oid);
        }

        if (userAdd.length > 0) {
            value.addUsers = userAdd.map((x) => ({
                userOid: x.oid,
                alertOn: x.alertOn || [],
            }));
        }

        if (!isEqual(tags, detailsRow.associatedTags)) {
            value.addAssociatedTags = tags;
        }

        if (removeTags.length > 0) {
            value.removeAssociatedTags = removeTags;
        }

        if (!isEqual(triggerTime, detailsRow.triggerTime)) {
            value.triggerTime = triggerTime;
        }

        if (!triggerTime.every(({ startTime, endTime }) => {
            const toMinutes = (time) => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };
            return toMinutes(endTime) >= toMinutes(startTime);
        })) return false;

        const newOverspeeding = {
            speedLimit: addAlertValue?.overspeeding?.speedLimit,
            actions: {
                speedLimitBreach: {
                    enabled: addAlertValue?.overspeeding?.actions?.speedLimitBreach?.enabled,
                },
            },
        };

        if (!isEqual(newOverspeeding, detailsRow.overspeeding)) {
            value.overspeeding = newOverspeeding;
        }

        return Object.keys(value).length > 0;
    };

    useEffect(() => {
        const isValidTriggerTime = triggerTime?.every(({ startTime, endTime }) => {
            const toMinutes = (time) => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };
            return toMinutes(endTime) >= toMinutes(startTime);
        });
        if (detailsRow) {
            if (checkingButtonDisabled()) {
                setDisableMainButton(true);
            } else {
                setDisableMainButton(false);
            }
        } else {
            if (addAlertValue.alertName) {
                if (triggerTime) {
                    isValidTriggerTime ? setDisableMainButton(true) : setDisableMainButton(false);
                } else {
                    setDisableMainButton(true);
                }
            } else {
                setDisableMainButton(false);
            }
        }
    }, [addAlertValue, tags, listUser, descriptionValue, triggerTime, detailsRow]);

    const finalCreateButton = () => {
        try {
            if (detailsRow) {
                const updateList = checkingReducer();
                updateAlert(updateList);
            } else {
                const reducer = {
                    alertPolicy: {
                        name: addAlertValue.alertName,
                        isEnabled: true,
                        notifiedUsers: listUser.length > 0
                            ? listUser.map((x) => ({
                                userOid: x.oid,
                                alertOn: x.alertOn || [],
                            })) : [],
                        associatedTags: tags,
                        alertType: selectionList.value,
                        agencyOid: agencyID,
                        description: descriptionValue,
                        timezone: currentAgencyTimezone,
                        triggerTime: triggerTime,
                        overspeeding: {
                            speedLimit: addAlertValue?.overspeeding?.speedLimit,
                            actions: {
                                speedLimitBreach: {
                                    enabled: addAlertValue?.overspeeding?.actions?.speedLimitBreach?.enabled,
                                },
                            },
                        },
                    },
                };
                createAlert(reducer);
            }
        } catch (err) {
            console.log('error:', err);
        }
    };

    const formatTimezone = (timezone) => {
        const now = moment.tz(timezone);
        const offset = now.format('Z');
        return `${timezone.replace('_', ' ')} UTC${offset}`;
    };

    return (
    <div className="geofence-alert-details-container">
        <div className="geofence-alert-details-policy-container">
            <Text
              text="Alert Policy Name"
              weight="500"
              size="0.875rem"
              height="1.25rem"
              color="#344054"
            />
            <InputText placeholder="" value={addAlertValue.alertName} onChange={inputOnChangeAlertName} width="508px" />
        </div>
        <div className="geofence-alert-details-description-container">
            <div className="configuration-body-row">
                <div className="configuration-text-container">
                    <div className="configuration-main-text">
                        Description
                    </div>
                    <div className="configuration-supporting-text">
                        Add a brief description to further identify the alert or for specific notes
                    </div>
                </div>
                <TextArea descriptionValue={descriptionValue} setDescriptionValue={setDescriptionValue} width="535px" />
            </div>
        </div>
        <div className="geofence-alert-details-areas-container">
            <div>
                <Text
                  text="Speed Limit (km/h)"
                  weight="500"
                  size="0.875rem"
                  height="1.25rem"
                  color="#344054"
                />
            </div>
            <InputText placeholder="" value={addAlertValue?.overspeeding?.speedLimit} onChange={inputOnChangeSpeedLimit} width="508px" />
        </div>
        {/* Actions */}
        <div className="geofence-alert-details-description-container">
            <div className="configuration-body-row">
                <div className="configuration-text-container">
                    <div className="configuration-main-text">
                        Actions
                    </div>
                </div>
            </div>
            <div>
                {/* Notify Upon speed limit breach */}
                <div className="g-detail-action-checkbox-container" style={{ marginBottom: '16px' }}>
                    <div style={{ position: 'relative', top: '3px' }}>
                        <Checkbox
                          width="16px"
                          height="16px"
                          borderRadius="4px"
                          name="statusChange"
                          checked={addAlertValue?.overspeeding?.actions?.speedLimitBreach?.enabled}
                          onChange={handleSpeedLimitBreachChange}
                        />
                    </div>
                    <div>
                        <Text
                          text="Notify upon speed limit breach"
                          weight="500"
                          size="0.875rem"
                          height="1.25rem"
                          color="#344054"
                        />
                        <div className="g-detail-action-checkbox-text-header">
                            Triggers the notification when an asset exceeds the set speed limit
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="geofence-alert-details-areas-container">
            <div>
                <Text
                  text="Asset/Device Tags"
                  weight="500"
                  size="0.875rem"
                  height="1.25rem"
                  color="#344054"
                />
                <Text
                  text="Grant permission to tagged resources. Leave blank to grant permission to all resources"
                  weight="400"
                  size="0.875rem"
                  height="1.25rem"
                  color="#475467"
                />
            </div>
            <InputTags
              tags={tags}
              setTags={setTags}
              removedTags={removeTags}
              setRemovedTags={setRemoveTags}
              tagsHistory={tagsHistory}
            />
        </div>
        <div className="geofence-alert-details-areas-container">
            <div>
                <Text
                  text="Alert Trigger Time"
                  weight="500"
                  size="0.875rem"
                  height="1.25rem"
                  color="#344054"
                />
                <Text
                  text={(
                    <>
                        Leave all days unchecked to trigger the alert at all times.
                        <br />
                        Alerts will be triggered based on the
                        {' '}
                        {formatTimezone(currentAgencyTimezone)}
                        {' '}
                        time zone.
                    </>
                  )}
                  weight="400"
                  size="0.875rem"
                  height="1.25rem"
                  color="#475467"
                />
            </div>
            <div className="time-selector-container" style={{ marginBottom: '-15px' }}>
                <TimeSelector triggerTime={triggerTime} setTriggerTime={setTriggerTime} />
            </div>
        </div>
        <div className="geofence-alert-details-areas-container">
            <div>
                <Text
                  text="Notified Users"
                  weight="500"
                  size="0.875rem"
                  height="1.25rem"
                  color="#344054"
                />
                <Text
                  text="Select users to be notified when selected action(s) have been triggered"
                  weight="400"
                  size="0.875rem"
                  height="1.25rem"
                  color="#475467"
                />
            </div>
            <GeofenceActionUser
              users={listUser}
              listDropdown={teamList}
              newUserFunction={addNewUser}
              userCancel={userCancel}
              disabledAddUser={disabledAddUser}
              alignment="flex-end"
              saveUser={saveUserButton}
              saveUpdateUser={saveUpdateUserButton}
              editUser={editUser}
              removeUser={removeUser}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              loading={loading}
              editingIndex={editingIndex}
              setDisabledAddUser={setDisabledAddUser}
            />
        </div>
        <div className="g-detail-button-container">
            <div className="geofence-alert-details-button">
                <SecondaryButton label="Cancel" onClickFunction={backButton} />
                <PrimaryButton label={detailsRow ? 'Update' : 'Create'} disable={!disableMainButton} loading={loading} onClickFunction={finalCreateButton} />
            </div>
        </div>
    </div>
    );
}
